import { Component, OnInit, Inject } from '@angular/core';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { SessionService } from '../../../../../../shared/session/session.service';
import { Router } from '@angular/router';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { SuspendAutomaticPaymentsFlowService } from '../services/suspend-automatic-payments-flow.service';
import { WARNING_MESSAGES } from '../../../../shared/constants/warning-messages';
import { BillingCommonNavigationsService } from '../../../../../../billing/shared/billing-common-navigations.service';
import { MAKE_A_PAYMENT } from '../../../../../../shared/constants/common.constants';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { BillingEBIOptions, PaymentMethodsData } from '../../../../../../shared/service/billing-api-common.service';
import { CtmEventType, CtmProductType } from '../../../../../../shared/service/ctm-service/ctm-model';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { HttpCachingService } from '../../../../../../shared/service/http-caching-service/http-caching.service';

@Component({
    selector: 'app-suspend-automatic-payments-confirmation',
    templateUrl: './suspend-automatic-payments-confirmation.component.html',
    styleUrls: ['./suspend-automatic-payments-confirmation.component.scss']
})
export class SuspendAutomaticPaymentsConfirmationComponent extends BasePageComponent implements OnInit {
    pageName = ManagePaymentPreferencesPageTitle.automaticPayments.suspend.confirmation;
    payNowOrPayLater: 'payNow' | 'payLater';

    // eslint-disable-next-line max-params
    constructor(
        protected session: SessionService,
        protected router: Router,
        private suspendAutomaticPaymentsFlowService: SuspendAutomaticPaymentsFlowService,
        @Inject('window') private window: Window,
        private billingCommonNavigationsService: BillingCommonNavigationsService,
        private eventService: BillingEventService,
        private httpCachingService: HttpCachingService,
        @Inject('logger') private logger: LoggerService
    ) {
        super(session, router);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.sendOnPageLoadEbiEvent();
        this.sendSuccessCtmEvent();
        this.httpCachingService.clearCache();
    }

    onMakeAPayment(): void {
        this.logger.info('On click of Suspend Automatic Payments Make a Payment link');
        this.payNowOrPayLater = 'payNow';
        this.sendOnClickOfPayLaterOrMAPButtonEbiEvent();
        this.suspendAutomaticPaymentsFlowService.clear();
        this.billingCommonNavigationsService.navigateToMakeAPayment();
    }

    onPayLater(): void {
        this.payNowOrPayLater = 'payLater';
        this.sendOnClickOfPayLaterOrMAPButtonEbiEvent();
        this.suspendAutomaticPaymentsFlowService.clear();
        this.billingCommonNavigationsService.navigateToPaymentPreferences();
    }

    showPrintDialog(): void {
        this.sendOnClickOfCancelButtonEbiEvent();
        this.window.print();
    }

    sendOnPageLoadEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_PAGE_LOAD_OF_SUSPEND_EASY_PAY_CONFIRMATION_PAGE,
            this.getBillingEBIOptions());
    }

    sendOnClickOfCancelButtonEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_CLICK_OF_PRINT_BTN_SUSPEND_EASY_PAY_CONFIRMATION_PAGE,
            this.getBillingEBIOptions());
    }

    sendOnClickOfPayLaterOrMAPButtonEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_CLICK_OF_PAY_LATER_OR_MAP_BTN_SUSPEND_EASY_PAY_CONFIRMATION_PAGE,
            {
                firstName: this.session.firstName,
                lastName: this.session.lastName,
                paymentMethod: this.getPaymentInfo(this.payNowOrPayLater),
                paymentPlan: this.suspendAutomaticPaymentsFlowService.paymentPlan
            });
    }

    sendSuccessCtmEvent(): void {
        if (!this.suspendAutomaticPaymentsFlowService.apiCallFailed) {
            this.eventService.handleCtmEvent(
                CtmEventType.SUSPEND_EASY_PAY_SUCCESSFUL, {
                productType: CtmProductType.BILLING
            });
        }
    }

    getPaymentInfo(payNowOrPayLater: string): PaymentMethodsData[] {
        const paymentMethodValueData = [];
        paymentMethodValueData.push({
            firstName: '',
            lastName: '',
            easyPay: '',
            paperless: '',
            paymentAmount: '',
            paymentDate: '',
            accountType: '',
            clientType: '',
            defaultMethod: '',
            nextDueDate: '',
            nextPaymentAmount: '',
            payNowPayLater: payNowOrPayLater
        });
        return paymentMethodValueData;
    }

    private getBillingEBIOptions(): BillingEBIOptions {
        return {
            paymentAmount: this.suspendAutomaticPaymentsFlowService.amountDue,
            firstName: this.session.firstName,
            lastName: this.session.lastName,
            responseCode: this.suspendAutomaticPaymentsFlowService.apiResponseCode,
            responseStatus: this.apiCallFailed ? 'ERROR' : 'SUCCESS',
            paymentPlan: this.suspendAutomaticPaymentsFlowService.paymentPlan
        };
    }

    get suspendEasyPayError(): boolean {
        return this.suspendAutomaticPaymentsFlowService.setupSuspendAutomaticPaymentsError;
    }

    get apiCallFailed(): boolean {
        return this.suspendAutomaticPaymentsFlowService.apiCallFailed;
    }

    get apiServiceDownMessage(): string {
        return WARNING_MESSAGES.API_FAILURE;
    }

    get upNextLabel(): string {
        return MAKE_A_PAYMENT;
    }

    get dueDate(): Date {
        return this.suspendAutomaticPaymentsFlowService.amountDueDate;
    }

    get nextDueAmount(): string {
        return this.suspendAutomaticPaymentsFlowService.amountDue;
    }
}
