import { Component, OnInit } from '@angular/core';
import { PaymentPreferencesStateService } from '../../../../shared/services/payment-preferences-state/payment-preferences-state.service';
import { HttpCachingService } from '../../../../../../shared/service/http-caching-service/http-caching.service';

@Component({
    selector: 'app-edit-refund-method-confirmation',
    templateUrl: './edit-refund-method-confirmation.component.html',
    styleUrls: ['./edit-refund-method-confirmation.component.scss']
})
export class EditRefundMethodConfirmationComponent implements OnInit {
    constructor(
        private paymentPreferencesState: PaymentPreferencesStateService,
        private httpCachingService: HttpCachingService
    ) { }

    onContinue(): void {
        this.paymentPreferencesState.resetState();
    }

    ngOnInit(): void {
        this.httpCachingService.clearCache();
    }
}
