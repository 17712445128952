import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedBillingComponentsModule } from '@nationwide/dgs-angular-billing-common';
import { InternetServicingAngularComponentsModule } from '@nationwide/internet-servicing-angular-components';
import { ISBSharedComponentsModule } from '../../shared/shared-components.module';
import { BoltWrapperModule } from '@nationwide/internet-servicing-bolt-components';
import { WaysToPayAdapter } from '../shared/adapters/ways-to-pay.adapter';
import { WaysToPayModalComponent } from './ways-to-pay-modal/ways-to-pay-modal.component';
import { WaysToPayComponent } from './ways-to-pay-widget/ways-to-pay.component';
import { TimeoutModalService } from '../../shared/session/time-out/time-out.service';

@NgModule({
    imports: [
        CommonModule,
        InternetServicingAngularComponentsModule,
        ISBSharedComponentsModule,
        SharedBillingComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        BoltWrapperModule
    ],
    declarations: [
        WaysToPayComponent,
        WaysToPayModalComponent
    ],
    exports: [
        WaysToPayComponent,
        WaysToPayModalComponent
    ],
    providers: [
        WaysToPayAdapter,
        TimeoutModalService
    ]
})
export class WaysToPayModule { }
