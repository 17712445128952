import { Injectable, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {
    DistributionPartnerInformationService,
    DPIMInfoQueryParametersHelperService,
    GetDPIMQueryParameters,
    DistributionPartnerAPI
} from '@nationwide/internet-servicing-dpim-requests';
import {
    enterpriseCIMServiceToken,
    EnterpriseCIMService
} from '@nationwide/dgs-angular-billing-common';
import { Observable, forkJoin, of, mergeMap, map, catchError, publishReplay, refCount } from 'rxjs';
import { SessionService } from '../../../shared/session/session.service';
import { DistributionPartnerRoleInfoHelperService } from './distribution-partner-role-info-helper.service';
@Injectable()
export class DistributionPartnerRoleInfoService {
    callInternetRegistrations: any;
    agentState = '';
    producerNumber = '';
    producerType = '';
    accessToken = '';
    guid = '';
    // eslint-disable-next-line max-params
    constructor(
        private session: SessionService,
        @Inject(enterpriseCIMServiceToken)
        private ecimService: EnterpriseCIMService,
        private distributionPartnerInformationService: DistributionPartnerInformationService,
        private dpimInfoQueryParamsHelperService: DPIMInfoQueryParametersHelperService,
        private distributionPartnerRoleInfoHelperService: DistributionPartnerRoleInfoHelperService
    ) {
    }

    fetchDistributionPartnerRoleInfo(): Observable<DistributionPartnerAPI.DpimRoleInfoResponse | HttpErrorResponse> {
        return this.retrieveInternetRegistrations().pipe(
            mergeMap((internetRegistrationsResponse) => {
                if (internetRegistrationsResponse) {
                    if (internetRegistrationsResponse?.error) {
                        return of(internetRegistrationsResponse);
                    }
                    const abbrevation = this.distributionPartnerRoleInfoHelperService.getStateFromInternetRegistrationResponse(internetRegistrationsResponse);
                    const agreement = this.distributionPartnerRoleInfoHelperService.getCurrentAgreement(internetRegistrationsResponse);
                    this.agentState = this.distributionPartnerRoleInfoHelperService.getStateName(abbrevation);
                    this.producerNumber = this.distributionPartnerRoleInfoHelperService.getProducerNumbers(agreement);
                    this.producerType = this.distributionPartnerRoleInfoHelperService.retrieveProducerType(agreement);
                    this.session.dpimProducerType = this.producerType;
                    let queryparams: GetDPIMQueryParameters = null;
                    if (this.producerNumber && this.agentState) {
                        queryparams = this.dpimInfoQueryParamsHelperService.retrieveDPIMQueryParams(this.producerNumber, this.agentState);
                        this.session.producerNumber = queryparams?.agentNumber;
                    }

                    if (this.agentState && queryparams && queryparams.agentNumber) {
                        return this.getDistributionPartnerRole(queryparams);
                    } else {
                        return of(null);
                    }
                } else {
                    return of(null);
                }
            })
        );
    }

    retrieveInternetRegistrations(): Observable<any> {
        return forkJoin({
            internetRegistrationResponse: this.getInternetRegistrations()
        }).pipe(
            map((responses) => responses)
        );
    }

    getDistributionPartnerRole(queryparams: GetDPIMQueryParameters): Observable<DistributionPartnerAPI.DpimRoleInfoResponse | HttpErrorResponse> {
        return this.distributionPartnerInformationService.retrieveDistributionPartnerRoleInfo({
            accessToken: this.accessToken,
            agentNumber: queryparams?.agentNumber,
            query: this.distributionPartnerRoleInfoHelperService.generateQueryString(this.producerType, queryparams)
        }).pipe(
            catchError((error: HttpErrorResponse) => of(error))
        );
    }

    getInternetRegistrations(): Observable<any> {
        if (!this.callInternetRegistrations) {
            this.accessToken = this.session.accessToken;
            this.guid = this.session.guid;
            this.callInternetRegistrations = this.ecimService.getInternetRegistrations({
                accessToken: this.session.accessToken,
                guid: this.session.guid
            }).pipe(
                publishReplay(1),
                refCount(),
                catchError(() => of(null))
            );
        }
        return this.callInternetRegistrations;
    }
}

