import { Component, Inject, OnInit } from '@angular/core';
import { Observable, of, catchError, switchMap, tap } from 'rxjs';
import { RecapFormField } from '../../../../../../shared/payments/recap-form-field/recap-form-field.component';
import { AddRefundMethodFlow, AddRefundMethodFlowService } from '../services/add-refund-method-flow.service';
import { TitleCasePipe } from '@angular/common';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { Router } from '@angular/router';
import { environment } from '../../../../../../../environments/environment';
import { SubscribingComponent } from '../../../../../../shared/base/subscribing.component';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { CtmEventType, CtmProductType } from '../../../../../../shared/service/ctm-service/ctm-model';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { PaymentMethodsData } from '../../../../../../shared/service/billing-api-common.service';
import { AddRefundMethodReviewService } from './add-refund-method-review.service';
import { BankAccountPaymentItem } from '../../../../shared/models/bankaccount-item.model';
import { OmsResponse } from '@nationwide/dgs-angular-billing-common/api-response-types/internet-servicing-billing-experience';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
@Component({
    selector: 'app-add-refund-method-review',
    templateUrl: './add-refund-method-review.component.html',
    styleUrls: ['./add-refund-method-review.component.scss']
})
export class AddRefundMethodReviewComponent extends SubscribingComponent implements OnInit {
    flowServiceData: AddRefundMethodFlow;
    addRefundMethodReviewDetails: RecapFormField[];
    disclaimerMessage: Observable<string>;
    loadComplete = false;

    // eslint-disable-next-line max-params
    constructor(
        private addRefundMethodFlowService: AddRefundMethodFlowService,
        private titleCasePipe: TitleCasePipe,
        private router: Router,
        private urlUtil: UrlUtil,
        private eventService: BillingEventService,
        @Inject('logger') private logger: LoggerService,
        private addRefundMethodReviewService: AddRefundMethodReviewService) {
        super();
    }

    ngOnInit(): void {
        this.fetchFlowServiceData();
        this.fetchDisclaimerMessage();
    }

    submit(): void {
        this.loadComplete = false;
        const selectedPaymentMethod = this.addRefundMethodFlowService.selectedPaymentMethod;
        this.nextSub = this.addRefundMethodReviewService.addRefundMethod(selectedPaymentMethod)
            .pipe(
                tap(() => {
                    this.addRefundMethodFlowService.apiCallSuccessful = true;
                    this.addRefundMethodEventHandler();
                }),
                switchMap(() => this.sendOmsEmail()),
                catchError((error) => {
                    this.logger.error('API ERROR: ADD REFUND METHOD SUBMISSION', error);
                    this.addRefundMethodFlowService.apiCallSuccessful = false;
                    return of(error);
                }))
            .subscribe(() => this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SAVED_REFUND_METHOD.ADD.CONFIRMATION(
                this.urlUtil.hashParamsString
            )));
    }

    cancelAddRefundMethod(): void {
        this.addRefundMethodFlowService.clear();
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(
            this.urlUtil.hashParamsString
        ));
    }

    navigateToLanding(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SAVED_REFUND_METHOD.ADD.LANDING(
            this.urlUtil.hashParamsString
        ));
    }

    fetchFlowServiceData(): void {
        this.flowServiceData = this.addRefundMethodFlowService.fetchFlowData();
    }

    fetchDisclaimerMessage(): void {
        this.disclaimerMessage = this.addRefundMethodReviewService.fetchDisclaimerMessage().pipe(
            tap(() => this.loadComplete = true)
        );
    }

    addRefundMethodEventHandler(): void {
        this.addRefundMethodEbiEvent();
        this.addRefundMethodCtmEvent();
    }

    addRefundMethodEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.PP_ADD_REFUND_METHOD, {
            paymentMethod: this.ebiPaymentMethodData()
        });
    }

    addRefundMethodCtmEvent(): void {
        this.eventService.handleCtmEvent(
            CtmEventType.PP_ADD_REFUND_METHOD, {
            productType: CtmProductType.BILLING
        });
    }

    sendOmsEmail(): Observable<OmsResponse> {
        return this.addRefundMethodReviewService.addRefundMethodEmail();
    }

    private ebiPaymentMethodData(): PaymentMethodsData[] {
        const paymentItem = this.addRefundMethodFlowService.selectedPaymentMethod;
        const data = [];
        const accountType = (<BankAccountPaymentItem>paymentItem.item).accountType;

        data.push({
            accountType
        });
        return data;
    }

    private fetchFullName({ firstName, middleInitial, lastName }: BankAccountPaymentItem): string {
        return middleInitial ? `${firstName} ${middleInitial} ${lastName}` : `${firstName} ${lastName}`;
    }

    get recapEmailAddressField(): RecapFormField {
        return {
            id: 'email-address',
            label: 'Email address',
            value: this.flowServiceData.emailAddress
        };
    }

    get recapBankAccountFields(): RecapFormField[] {

        const selectedPayMethod: BankAccountPaymentItem = <BankAccountPaymentItem> this.addRefundMethodFlowService.selectedPaymentMethod.item;
        const recapFields = [
            {
                id: 'account-type-recap',
                label: 'Account type',
                value: this.titleCasePipe.transform(selectedPayMethod.accountType)
            },
            {
                id: 'account-holders-name',
                label: 'Account holder\'s name',
                value: this.titleCasePipe.transform(this.fetchFullName(selectedPayMethod))
            },
            {
                id: 'bank-routing-number-recap',
                label: 'Bank routing number',
                value: selectedPayMethod.routingNumber
            },
            {
                id: 'bank-name-recap',
                label: 'Bank name',
                value: selectedPayMethod.bankName
            },
            {
                id: 'account-number-recap',
                label: 'Account number',
                value: `*********${selectedPayMethod.maskedBankAccountNumber}`
            }
        ];
        if (this.addRefundMethodFlowService.selectedPaymentMethod) {
            const ACCOUNT_HOLDER_ADDRESS_INDEX = 2;
            const ITEMS_TO_DELETE = 0;
            recapFields.splice(
                ACCOUNT_HOLDER_ADDRESS_INDEX,
                ITEMS_TO_DELETE,
                {
                    id: 'account-holders-address',
                    label: 'Account holder\'s address',
                    value: `${this.titleCasePipe.transform(`${selectedPayMethod.street}, ${selectedPayMethod.city}`)}, ${selectedPayMethod.state.toUpperCase()} ${selectedPayMethod.zip}`
                }
            );
        }
        return recapFields;
    }
}