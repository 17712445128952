import { ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { IframeQueueService } from '../iframe-queue.service';

@Component({
    selector: 'app-iframe-wrapper',
    templateUrl: './iframe-wrapper.component.html'
})
export class IframeWrapperComponent implements OnInit, OnDestroy {
    iframeUrl = '';
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    listener: Function;
    timeoutId = null;

    constructor(
        private iframeQueueService: IframeQueueService,
        private renderer: Renderer2,
        private changeDetectorRef: ChangeDetectorRef
    ) { }

    ngOnDestroy(): void {
        if (this.listener) {
            this.listener();
        }
    }

    ngOnInit(): any {
        this.iframeQueueService.listenForIframeQueueInEvent().subscribe((nextUrl) => {
            if (nextUrl) {
                this.iframeUrl = nextUrl;
                this.timeboxIframeLoad();
                this.iframeQueueService.clearQueueIn();
            }
        });

        this.listener = this.renderer.listen('window', 'message', (event) => {
            if (event?.data?.type === 'EUA2_AUTHORIZE_RESPONSE') {
                this.iframeUrl = '';
                this.changeDetectorRef.detectChanges();
                this.iframeQueueService.nextOut(event.data.payload);
            }
        });
    }

    private timeboxIframeLoad(): void {
        if (this.timeoutId) {
            clearInterval(this.timeoutId);
            this.timeoutId = null;
        }

        const timeoutTime = 15000;
        this.timeoutId = setTimeout(() => {
            if (this.iframeUrl) {
                this.iframeUrl = '';
                this.changeDetectorRef.detectChanges();
                this.iframeQueueService.nextOut('CALL_TIMEOUT');
            }
            this.timeoutId = null;
        }, timeoutTime);
    }
}
