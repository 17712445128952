import { Inject, Injectable } from '@angular/core';
import { IsbExperienceService, isbExperienceServiceToken, MoneyProcessingService, ContentfulMapperService } from '@nationwide/dgs-angular-billing-common';
import { OmsResponse } from '@nationwide/dgs-angular-billing-common/api-response-types/internet-servicing-billing-experience';
import { Observable, map } from 'rxjs';
import { SessionService } from '../../../../../../shared/session/session.service';
import { PaymentItem } from '../../../../shared/models/payment-item.model';
import { BankAccountPaymentItem } from '../../../../shared/models/bankaccount-item.model';

import { AddRefundMethodFlowService } from '../services/add-refund-method-flow.service';

@Injectable()
export class AddRefundMethodReviewService {
    // eslint-disable-next-line max-params
    constructor(
        @Inject(isbExperienceServiceToken) private isbExperience: IsbExperienceService,
        private moneyProcessing: MoneyProcessingService,
        private sessionService: SessionService,
        private addRefundMethodFlowService: AddRefundMethodFlowService,
        readonly contentfulService: ContentfulMapperService
    ) { }

    addRefundMethod(refundMethod: PaymentItem): Observable<number> {
        return this.moneyProcessing.patchPayPlan({
            accessToken: this.sessionService.accessToken,
            billingSystem: this.sessionService.billingSystem,
            agreementNumber: this.sessionService.billingAccountNumber,
            payload: {
                billingPayload: {
                    enterpriseCustomerNumber: this.sessionService.ecn,
                    requestType: 'RefundMethodChange.PayPlanUpdate',
                    action: 'update',
                    currentBillingMethodType: 'Direct',
                    newBillingMethodType: 'Recurring EFT',
                    accountDueDate: '',
                    paymentMethod: {
                        electronicFundsTransfer: {
                            bankName: (<BankAccountPaymentItem>refundMethod.item).bankName,
                            bankAccountType: (<BankAccountPaymentItem>refundMethod.item).accountType,
                            bankAccountNumber: (<BankAccountPaymentItem>refundMethod.item).encryptedAccountNumber,
                            bankRoutingNumber: (<BankAccountPaymentItem>refundMethod.item).routingNumber,
                            maskedBankAccountNumber: (<BankAccountPaymentItem>refundMethod.item).maskedBankAccountNumber
                        },
                        paymentMethodType: 'ElectronicFundsTransfer.PaymentMethod',
                        payorInfo: undefined,
                        description: 'UNSET'
                    }
                }
            }
        });
    }

    fetchDisclaimerMessage(): Observable<string> {
        const contenfulResponse = this.contentfulService.getContentfulBillingContent();
        return contenfulResponse.pipe(
            map((contenfulData) => contenfulData.addSavedRefundMethodAuthDisclaimer.content));
    }

    addRefundMethodEmail(): Observable<OmsResponse> {
        return this.isbExperience.sendAddRefundMethodEmail({
            accessToken: this.sessionService.accessToken,
            billingSystem: this.sessionService.billingSystem,
            updatedEmail: this.addRefundMethodFlowService.emailAddress
        });
    }
}