import { Injectable } from '@angular/core';
import { UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { PaymentPreferencesStateService } from '../../../../billing/payment-preferences/shared/services/payment-preferences-state/payment-preferences-state.service';
import { BasePageComponent } from '../../../base/base-page.component';
import { SaveAsRefundMethodService } from '../../../payments/consider-this/save-as-refund-method/services/save-as-refund-method.service';

@Injectable()
export class StateClearingGuard implements CanDeactivate<BasePageComponent> {
    constructor(
        private paymentPreferencesState: PaymentPreferencesStateService,
        private saveAsRefundMethodService: SaveAsRefundMethodService
    ) { }

    canDeactivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        this.paymentPreferencesState.resetState();
        this.saveAsRefundMethodService.clearCache();
        return true;
    }
}
