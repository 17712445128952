import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EasternTimeDatePipe } from '@nationwide/internet-servicing-angular-pipes';

import { ContentfulMapperService } from '@nationwide/dgs-angular-billing-common';
import { Observable, finalize, map } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { PaymentPreferencesStateService } from '../../../../shared/services/payment-preferences-state/payment-preferences-state.service';
import { AddRefundMethodFlow, AddRefundMethodFlowService } from '../services/add-refund-method-flow.service';
import { HttpCachingService } from '../../../../../../shared/service/http-caching-service/http-caching.service';
import { BankAccountPaymentItem } from '../../../../shared/models/bankaccount-item.model';

@Component({
    selector: 'app-add-refund-method-confirmation',
    templateUrl: './add-refund-method-confirmation.component.html',
    styleUrls: ['./add-refund-method-confirmation.component.scss']
})
export class AddRefundMethodConfirmationComponent implements OnInit {
    loadComplete = false;
    confirmationMessage: string;
    effectiveDate: string;
    successNotification = 'Your request has been processed';
    errorNotification: Observable<string>;
    flowData: AddRefundMethodFlow;

    // eslint-disable-next-line max-params
    constructor(
        private paymentPreferencesState: PaymentPreferencesStateService,
        private addRefundMethodFlowService: AddRefundMethodFlowService,
        private easternTimeDatePipe: EasternTimeDatePipe,
        private router: Router,
        private urlUtil: UrlUtil,
        readonly contentfulMapperService: ContentfulMapperService,
        private httpCachingService: HttpCachingService,
        @Inject('window') private window: Window
    ) { }

    ngOnInit(): void {
        this.initialize();
    }

    initialize(): void {
        this.flowData = this.addRefundMethodFlowService.fetchFlowData();
        this.httpCachingService.clearCache();
        if (this.flowData.apiCallIsSuccessful) {
            this.setConfirmationMessage();
            this.loadComplete = true;
        } else {
            this.errorNotification = this.setSystemDownMessage().pipe(
                finalize(() => this.loadComplete = true)
            );
        }
    }

    setConfirmationMessage(): void {
        const timestamp = this.getConfirmationTimestamp();
        const effectiveTime = `${this.easternTimeDatePipe.transform(timestamp, 'MM/dd/yyyy, hh:mm aa')} ET`;
        const { paymentItem } = this.flowData;
        const bankName = (<BankAccountPaymentItem>paymentItem.item).bankName;
        const maskedBankAccountNumber = (<BankAccountPaymentItem>paymentItem.item).maskedBankAccountNumber;
        this.confirmationMessage = ` ${bankName} *********${maskedBankAccountNumber}`;
        this.effectiveDate = effectiveTime;
    }

    onContinue(): void {
        this.paymentPreferencesState.resetState();
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(this.urlUtil.hashParamsString));
    }

    showPrintDialog(): void {
        this.window.print();
    }

    secondayNavigation(): void {
        this.window.location.href = environment.CAM_HOME;
    }

    setSystemDownMessage(): Observable<string> {
        const contentfulResponse = this.contentfulMapperService.getContentfulBillingContent();
        return contentfulResponse.pipe(
            map((contentfulData) => contentfulData.apiFailure.content)
        );
    }

    getConfirmationTimestamp(): Date {
        return new Date();
    }

    get upNextLabel(): string {
        return 'Payment preferences';
    }
}