import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaymentItem } from '../../../billing/payment-preferences/shared/models/payment-item.model';
import { HeaderService } from '../../../shared/header/header.service';
import { AuthenticatedHeader, FooterData, UnauthenticatedHeader } from '@nationwide/internet-servicing-angular-components';
import { FooterService } from '../../../shared/footer/footer.service';
import { PaymentFlowType } from '../../shared/payment-flow-type.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PlaceholderPaymentItem } from '@nationwide/dgs-angular-billing-common';
import { WaysToPayPaymentMethodEmitterData } from '../ways-to-pay-widget/ways-to-pay.component';
import { SessionService } from '../../../shared/session/session.service';
import { TimeoutModalService } from '../../../shared/session/time-out/time-out.service';
declare let jQuery: any;
@Component({
    selector: 'app-ways-to-pay-modal',
    templateUrl: './ways-to-pay-modal.component.html',
    styleUrls: ['./ways-to-pay-modal.component.scss']
})
export class WaysToPayModalComponent implements OnInit, OnDestroy {
    onLinkClick: (linkMap: { url: string }) => void;

    header: AuthenticatedHeader;
    unauthenticatedHeader: UnauthenticatedHeader;
    footer: FooterData;
    private _selectedPaymentMethod: PaymentItem;
    canShowNavigationBar = true;
    shouldDisableContinueButton = true;
    isQuickPay = false;

    // eslint-disable-next-line max-params
    constructor(
        private paymentFlowType: PaymentFlowType,
        private headerService: HeaderService,
        private footerService: FooterService,
        private activeModal: NgbActiveModal,
        protected session: SessionService,
        private timeoutModalService: TimeoutModalService
    ) {
        this.timeoutModalService.registerModalInstance(this.activeModal, 'waysToPayModal');
    }

    ngOnDestroy(): void {
        this.timeoutModalService.unregisterModalInstance(this.activeModal);
    }

    ngOnInit(): void {
        if (this.paymentFlowType.isQuickpay) {
            this.isQuickPay = true;
            this.fetchUnauthenticatedHeader();
        } else {
            this.fetchHeader();
        }
        this.fetchFooter();
        this._selectedPaymentMethod = new PaymentItem(new PlaceholderPaymentItem(''));
    }

    closeModal(): void {
        this.activeModal.close('back');
        window.scroll(0, 0);
    }

    closeModalWithPaymentMethod(): void {
        this.activeModal.close(this._selectedPaymentMethod);
        window.scroll(0, 0);
    }

    private fetchUnauthenticatedHeader(): void {
        this.headerService.fetchUnauthenticatedHeader(
            (header: UnauthenticatedHeader) => this.unauthenticatedHeader = header
        );
    }

    private fetchHeader(): void {
        this.session.onInitialization.subscribe(() => {
            this.headerService.fetchHeaderData({
                lastLogin: '',
                username: this.session.firstName,
                isBillingTimelineNotEligible: this.session.hasLifePolicy,
                isCyberLife: this.paymentFlowType.isCyberLife
            },
                (header: AuthenticatedHeader) => this.header = header);
        });
    }

    private fetchFooter(): void {
        this.footer = this.footerService.defaultFooter();
        this
            .footerService
            .fetchFooter(!this.paymentFlowType.isQuickpay)
            .subscribe((footer) => this.footer = footer);
    }

    handleWaysToPayEmitter(paymentMethodEmitterData: WaysToPayPaymentMethodEmitterData): void {
        this._selectedPaymentMethod = paymentMethodEmitterData.paymentMethod;
        this.canShowNavigationBar = paymentMethodEmitterData.canShowNavigationBar;
        this.shouldDisableContinueButton = this._selectedPaymentMethod.isPlaceholder;
        if (this._selectedPaymentMethod.isNewMethod) {
            this.closeModalWithPaymentMethod();
        }
    }

    set iframeHeight(iframeHeight: number) {
        jQuery('app-ways-to-pay').height(iframeHeight);
    }

    getWaysToPayButtonLabel(): string {
        return this._selectedPaymentMethod?.getWaysToPayButtonLabel('Use') || 'Continue';
    }

    set selectedPaymentMethod(selectedPaymentMethod: PaymentItem) {
        this._selectedPaymentMethod = selectedPaymentMethod;
    }
}
