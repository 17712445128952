import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagePaymentPreferencesRoutingModule } from './manage-payment-preferences-routing.module';
import { RouterModule } from '@angular/router';
import { InternetServicingAngularComponentsModule } from '@nationwide/internet-servicing-angular-components';
import { ISBSharedComponentsModule } from '../../../shared/shared-components.module';
import { ManagePaymentPreferencesBaseComponent } from './base/manage-payment-preferences-base.component';
import { AddPaymentMethodComponent } from './saved-payment-methods/add/add-payment-method.component';
import { AddRefundMethodComponent } from './refund-method/add/add-refund-method.component';
import { EditRefundMethodComponent } from './refund-method/edit/edit-refund-method.component';
import { RemoveRefundMethodComponent } from './refund-method/remove/remove-refund-method.component';
import { SetupAutomaticPaymentsComponent } from './automatic-payments/setup/setup-automatic-payments.component';
import { EditAutomaticPaymentsComponent } from './automatic-payments/edit/edit-automatic-payments.component';
import { AddPaymentMethodLandingComponent } from './saved-payment-methods/add/landing/add-payment-method-landing.component';
import { AddPaymentMethodReviewComponent } from './saved-payment-methods/add/review/add-payment-method-review.component';
import { AddPaymentMethodConfirmationComponent } from './saved-payment-methods/add/confirmation/add-payment-method-confirmation.component';
import { EditPaymentMethodConfirmationComponent } from './saved-payment-methods/edit/confirmation/edit-payment-method-confirmation.component';
import { EditPaymentMethodReviewComponent } from './saved-payment-methods/edit/review/edit-payment-method-review.component';
import { EditPaymentMethodLandingComponent } from './saved-payment-methods/edit/landing/edit-payment-method-landing.component';
import { RemovePaymentMethodLandingComponent } from './saved-payment-methods/remove/landing/remove-payment-method-landing.component';
import { RemovePaymentMethodConfirmationComponent } from './saved-payment-methods/remove/confirmation/remove-payment-method-confirmation.component';
import { SetAsDefaultConfirmationComponent } from './saved-payment-methods/set-as-default/confirmation/set-as-default-confirmation.component';
import { SetAsDefaultLandingComponent } from './saved-payment-methods/set-as-default/landing/set-as-default-landing.component';
import { CancelAutomaticPaymentsLandingComponent } from './automatic-payments/cancel/landing/cancel-automatic-payments-landing.component';
import { CancelAutomaticPaymentsConfirmationComponent } from './automatic-payments/cancel/confirmation/cancel-automatic-payments-confirmation.component';
import { EditAutomaticPaymentsConfirmationComponent } from './automatic-payments/edit/confirmation/edit-automatic-payments-confirmation.component';
import { EditAutomaticPaymentsReviewComponent } from './automatic-payments/edit/review/edit-automatic-payments-review.component';
import { EditAutomaticPaymentsLandingComponent } from './automatic-payments/edit/landing/edit-automatic-payments-landing.component';
import { SetupAutomaticPaymentsLandingComponent } from './automatic-payments/setup/landing/setup-automatic-payments-landing.component';
import { SetupAutomaticPaymentsReviewComponent } from './automatic-payments/setup/review/setup-automatic-payments-review.component';
import { SetupAutomaticPaymentsConfirmationComponent } from './automatic-payments/setup/confirmation/setup-automatic-payments-confirmation.component';
import { AddRefundMethodConfirmationComponent } from './refund-method/add/confirmation/add-refund-method-confirmation.component';
import { AddRefundMethodReviewComponent } from './refund-method/add/review/add-refund-method-review.component';
import { AddRefundMethodLandingComponent } from './refund-method/add/landing/add-refund-method-landing.component';
import { EditRefundMethodLandingComponent } from './refund-method/edit/landing/edit-refund-method-landing.component';
import { EditRefundMethodReviewComponent } from './refund-method/edit/review/edit-refund-method-review.component';
import { EditRefundMethodConfirmationComponent } from './refund-method/edit/confirmation/edit-refund-method-confirmation.component';
import { RemoveRefundMethodConfirmationComponent } from './refund-method/remove/confirmation/remove-refund-method-confirmation.component';
import { RemoveRefundMethodLandingComponent } from './refund-method/remove/landing/remove-refund-method-landing.component';
import { PaymentFormControls } from '../../../shared/payments/payment-form-controls';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaymentPreferencesApiService } from '../shared/services/payment-preferences-api/payment-preferences-api.service';
import { PaymentPreferencesApiHttpService } from '../shared/services/payment-preferences-api/payment-preferences-api.http.service';
import { ManagePaymentPreferencesGuard } from './shared/guards/manage-payment-preferences.guard';
import { AddPaymentMethodFlowService } from './saved-payment-methods/add/services/add-payment-method-flow.service';
import { AddPaymentReview } from './saved-payment-methods/add/review/add-payment-review.model';
import { UrlUtil } from '../../../shared/url-util/url-util.service';
import { ManagePaymentPrefsAdapter } from './shared/adapters/manage-payment-prefs-adapter';
import { SharedBillingComponentsModule, EnterpriseCIMServiceImpl } from '@nationwide/dgs-angular-billing-common';
import { SavedPaymentConfimationDetailsComponent } from './shared/components/saved-payment-confimation-details/saved-payment-confimation-details.component';
import { AddPaymentMethodConfirmationService } from './saved-payment-methods/add/confirmation/add-payment-confirmation.service';
import { SetAsDefaultFlow } from './saved-payment-methods/set-as-default/services/set-as-default-flow.service';
import { SetAsDefaultBackFromConfirmationGuard } from './saved-payment-methods/set-as-default/guards/set-as-default-back-from-confirmation.guard';
import { SetAsDefaultConfirmationPageGuard } from './saved-payment-methods/set-as-default/guards/set-as-default-confirmation-page.guard';
import { EcimAdapter } from '../../../shared/adapters/ecim.adapter';
import { RemovePaymentMethodFlowService } from './saved-payment-methods/remove/services/remove-payment-method-flow.service';
import { RemovePaymentMethodConfirmationGuard } from './saved-payment-methods/remove/guards/remove-payment-method-confirmation.guard';
import { RemovePaymentMethodBackFromConfirmationGuard } from './saved-payment-methods/remove/guards/remove-payment-method-back-from-confirmation.guard';
import { SetupAutomaticPaymentsFlowService } from './automatic-payments/setup/services/setup-automatic-payments-flow.service';
import { EditPaymentMethodFlowService } from './saved-payment-methods/edit/edit-payment-method-flow.service';
import { EditPaymentMethodReview } from './saved-payment-methods/edit/review/edit-payment-review.model';
import { SetupAutomaticPaymentConfirmationService } from './automatic-payments/setup/confirmation/setup-automatic-payments-confirmation.service';
import { EditAutomaticPaymentConfirmationService } from './automatic-payments/edit/confirmation/edit-automatic-payments-confirmation.service';
import { EditPaymentMethodConfirmationService } from './saved-payment-methods/edit/confirmation/edit-payment-method-confirmation.service';
import { SetupAutomaticPaymentsConfirmationGuard } from './automatic-payments/setup/guards/setup-automatic-payments-confirmation.guard';
import { SetupAutomaticPaymentsBackFromConfirmationGuard } from './automatic-payments/setup/guards/setup-automatic-payments-back-from-confirmation.guard';
import { EditAutomaticPaymentFlowService } from './automatic-payments/edit/services/edit-automatic-payments-flow-service';
import { EditPaymentMethodReviewService } from './saved-payment-methods/edit/review/edit-payment-review.service';
import { SuspendAutomaticPaymentsLandingComponent } from './automatic-payments/suspend/landing/suspend-automatic-payments-landing.component';
import { SuspendAutomaticPaymentsConfirmationComponent } from './automatic-payments/suspend/confirmation/suspend-automatic-payments-confirmation.component';
import { SuspendAutomaticPaymentsFlowService } from './automatic-payments/suspend/services/suspend-automatic-payments-flow.service';
import { EditAutomaticPaymentLanding } from './automatic-payments/edit/landing/edit-automatic-payments-landing.model';
import { EditAutomaticPaymentsReview } from './automatic-payments/edit/review/edit-automatic-payments-review.model';
import { AddPaymentMethodReviewService } from './saved-payment-methods/add/review/add-payment-method-review.service';
import { EditAutomaticPaymentsReviewService } from './automatic-payments/edit/review/edit-automatic-payments-review.service';
import { SetupAutomaticPaymentsReviewService } from './automatic-payments/setup/review/setup-automatic-payments-review.service';
import { SuspendAutomaticPaymentsConfirmationGuard } from './automatic-payments/suspend/guards/suspend-automatic-payments-confirmation.guard';
import { SuspendAutomaticPaymentsBackFromConfirmationGuard } from './automatic-payments/suspend/guards/suspend-automatic-payments-back-from-confirmation.guard';
import { CancelAutomaticPaymentsConfirmationGuard } from './automatic-payments/cancel/guards/cancel-automatic-payments-confirmation.guard';
import { CancelAutomaticPaymentsBackFromConfirmationGuard } from './automatic-payments/cancel/guards/cancel-automatic-payments-back-from-confirmation.guard';
import { SetAsDefaultApiService } from './saved-payment-methods/set-as-default/services/set-as-default-api.service';
import { EditAutomaticPaymentsConfirmationGuard } from './automatic-payments/edit/guards/edit-automatic-payments-confirmation-guard';
import { EditAutomaticPaymentsBackFromConfirmationGuard } from './automatic-payments/edit/guards/edit-automatic-payments-back-from-confirmation-guard';
import { SetupAutomaticPaymentsReview } from './automatic-payments/setup/review/setup-automatic-payments-review.model';
import { SafeCurrencyPipe } from '../../../pay-bill/shared/safe-currency-pipe';
import { CancelScheduledFuturePaymentLandingComponent } from './scheduled-future-payments/cancel/landing/cancel-scheduled-future-payment-landing.component';
import { CancelScheduledFuturePaymentConfirmationComponent } from './scheduled-future-payments/cancel/confirmation/cancel-scheduled-future-payment-confirmation.component';
import { EditPaymentMethodConfirmationGuard } from './saved-payment-methods/edit/guards/edit-payment-method-confirmation-guard';
import { EditPaymentMethodBackFromConfirmationGuard } from './saved-payment-methods/edit/guards/edit-payment-method-back-from-confirmation-guard';
import { CancelScheduleFuturePaymentService } from './scheduled-future-payments/cancel/services/cancel-schedule-future-payment.service';
import { CancelScheduleFuturePaymentFlow } from './scheduled-future-payments/cancel/services/cancel-schedule-future-payment-flow.service';
import { CancelScheduleFuturePaymentConfirmationGuard } from './scheduled-future-payments/cancel/guards/cancel-scheduled-future-payment-confirmation-guard';
import { CancelFutureSchedulePaymentLandingGuard } from './scheduled-future-payments/cancel/guards/cancel-scheduled-future-payment-landing-guards';
import { CancelAutomaticPaymentsLandingService } from './automatic-payments/cancel/landing/cancel-automatic-payments-landing.service';
import { CancelAutomaticPaymentsFlowService } from './automatic-payments/cancel/shared/cancel-automatic-payments-flow.service';
import { EasyPayAuthorizationAgreementService } from '../../../shared/payments/ez-pay-authorization/easy-pay-authorization-agreement.service';
import { CancelScheduleFuturePaymentComponent } from './scheduled-future-payments/cancel/cancel-schedule-future-payment.component';
import { AddRefundMethodFlowService } from './refund-method/add/services/add-refund-method-flow.service';
import { RemoveRefundMethodFlowService } from './refund-method/remove/services/remove-refund-method-flow.service';
import { RemoveRefundMethodBackFromConfirmationGuard } from './refund-method/remove/guards/remove-refund-method-back-from-confirmation.guard';
import { RemoveRefundMethodConfirmationGuard } from './refund-method/remove/guards/remove-refund-method-confirmation.guard';
import { AddRefundMethodReviewService } from './refund-method/add/review/add-refund-method-review.service';
import { StateClearingGuard } from '../../../shared/service/guards/state-clearing-guard/state-clearing.guard';
import { BoltWrapperModule } from '@nationwide/internet-servicing-bolt-components';
import { EditPaymentPlanService } from './payment-plan/edit/landing/edit-payment-plan.service';
import { EditPaymentPlanComponent } from './payment-plan/edit/edit-payment-plan.component';
import { EditPaymentPlanLandingComponent } from './payment-plan/edit/landing/edit-payment-plan-landing.component';
import { PayPlanAdapter } from './automatic-payments/pay-plan/shared/pay-plan.adapter';
import { PayPlanFlow } from './automatic-payments/pay-plan/shared/pay-plan-flow.service';
import { EditPaymentPlanConfirmationComponent } from './payment-plan/edit/confirmation/edit-payment-plan-confirmation.component';
import { EditPayplanBackFromConfirmationGuard } from './payment-plan/edit/guards/edit-pay-plan-back-from-confirmation-guard';
import { WaysToPayModule } from '../../../pay-bill/ways-to-pay/ways-to-pay.module';

@NgModule({
    imports: [
        CommonModule,
        ManagePaymentPreferencesRoutingModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        InternetServicingAngularComponentsModule,
        ISBSharedComponentsModule,
        ReactiveFormsModule,
        SharedBillingComponentsModule,
        BoltWrapperModule,
        WaysToPayModule
    ],
    declarations: [
        ManagePaymentPreferencesBaseComponent,
        AddPaymentMethodComponent,
        AddRefundMethodComponent,
        EditRefundMethodComponent,
        RemoveRefundMethodComponent,
        SetupAutomaticPaymentsComponent,
        EditAutomaticPaymentsComponent,
        AddPaymentMethodLandingComponent,
        AddPaymentMethodReviewComponent,
        AddPaymentMethodConfirmationComponent,
        EditPaymentMethodConfirmationComponent,
        EditPaymentMethodReviewComponent,
        EditPaymentMethodLandingComponent,
        RemovePaymentMethodLandingComponent,
        RemovePaymentMethodConfirmationComponent,
        SetAsDefaultConfirmationComponent,
        SetAsDefaultLandingComponent,
        CancelAutomaticPaymentsLandingComponent,
        CancelAutomaticPaymentsConfirmationComponent,
        EditAutomaticPaymentsConfirmationComponent,
        EditAutomaticPaymentsReviewComponent,
        EditAutomaticPaymentsLandingComponent,
        SetupAutomaticPaymentsLandingComponent,
        EditPaymentPlanComponent,
        EditPaymentPlanLandingComponent,
        EditPaymentPlanConfirmationComponent,
        SetupAutomaticPaymentsReviewComponent,
        SetupAutomaticPaymentsConfirmationComponent,
        AddRefundMethodConfirmationComponent,
        AddRefundMethodReviewComponent,
        AddRefundMethodLandingComponent,
        EditRefundMethodLandingComponent,
        EditRefundMethodReviewComponent,
        EditRefundMethodConfirmationComponent,
        RemoveRefundMethodConfirmationComponent,
        RemoveRefundMethodLandingComponent,
        SavedPaymentConfimationDetailsComponent,
        SuspendAutomaticPaymentsLandingComponent,
        SuspendAutomaticPaymentsConfirmationComponent,
        CancelScheduleFuturePaymentComponent,
        CancelScheduledFuturePaymentLandingComponent,
        CancelScheduledFuturePaymentConfirmationComponent
    ],
    exports: [ISBSharedComponentsModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        PaymentFormControls,
        PaymentPreferencesApiService,
        PaymentPreferencesApiHttpService,
        ManagePaymentPreferencesGuard,
        SetAsDefaultFlow,
        AddPaymentMethodFlowService,
        AddPaymentReview,
        ManagePaymentPrefsAdapter,
        UrlUtil,
        AddPaymentMethodConfirmationService,
        AddPaymentMethodReviewService,
        SetAsDefaultBackFromConfirmationGuard,
        SetAsDefaultConfirmationPageGuard,
        EcimAdapter,
        RemovePaymentMethodFlowService,
        RemovePaymentMethodConfirmationGuard,
        RemovePaymentMethodBackFromConfirmationGuard,
        SetupAutomaticPaymentsFlowService,
        EditPaymentMethodFlowService,
        EditPaymentMethodReview,
        EditAutomaticPaymentLanding,
        EditAutomaticPaymentsReview,
        SetupAutomaticPaymentConfirmationService,
        EditAutomaticPaymentConfirmationService,
        EditPaymentMethodConfirmationService,
        SetupAutomaticPaymentsConfirmationGuard,
        SetupAutomaticPaymentsBackFromConfirmationGuard,
        EnterpriseCIMServiceImpl,
        EditAutomaticPaymentFlowService,
        EditPaymentMethodReviewService,
        SuspendAutomaticPaymentsFlowService,
        SetupAutomaticPaymentsReviewService,
        EditAutomaticPaymentsReviewService,
        SuspendAutomaticPaymentsConfirmationGuard,
        SuspendAutomaticPaymentsBackFromConfirmationGuard,
        CancelAutomaticPaymentsConfirmationGuard,
        CancelAutomaticPaymentsBackFromConfirmationGuard,
        EditPaymentMethodConfirmationGuard,
        EditPaymentMethodBackFromConfirmationGuard,
        SetAsDefaultApiService,
        EditAutomaticPaymentsConfirmationGuard,
        EditAutomaticPaymentsBackFromConfirmationGuard,
        SetupAutomaticPaymentsReview,
        SafeCurrencyPipe,
        CancelScheduleFuturePaymentService,
        CancelScheduleFuturePaymentFlow,
        CancelScheduleFuturePaymentConfirmationGuard,
        CancelFutureSchedulePaymentLandingGuard,
        CancelAutomaticPaymentsLandingService,
        CancelAutomaticPaymentsFlowService,
        RemoveRefundMethodFlowService,
        EasyPayAuthorizationAgreementService,
        StateClearingGuard,
        AddRefundMethodFlowService,
        AddRefundMethodReviewService,
        RemoveRefundMethodBackFromConfirmationGuard,
        RemoveRefundMethodConfirmationGuard,
        EditPaymentPlanService,
        PayPlanAdapter,
        PayPlanFlow,
        EditPayplanBackFromConfirmationGuard
    ]
})
export class ManagePaymentPreferencesModule { }
