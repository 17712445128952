/* eslint-disable no-extra-parens */
import { Component, HostListener, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthEmitterService, OAuthEvent } from '@nationwide/angular-oauth-module';
import { environment } from '../environments/environment';
import { TimeoutModalService } from './shared/session/time-out/time-out.service';
import { isExternal } from './shared/mixins/session-clearing.component';
import { ConsumerConfigurerService } from './billing-timeline-widget/service/consumer-configurer.service';
import { URL_CONSTANT, MILLISECONDS_IN_SECOND } from './shared/constants/common.constants';
import { PersonalBillingConfiguration } from '@nationwide/dgs-angular-billing-common';
import { SessionService } from './shared/session/session.service';
import { PaymentFlowType } from './pay-bill/shared/payment-flow-type.service';
import { UrlUtil } from './shared/url-util/url-util.service';
import { PingRedirectService } from './shared/service/ping-redirect/ping-redirect.service';
import { LoggerService } from './shared/logger/logger.service';
import { BillingEventService } from './shared/service/ebi-service/billing-events.service';
import { EBIEventType } from './shared/service/ebi-service/ebi-model';
import { FooterService } from './shared/footer/footer.service';
import { FooterData } from '@nationwide/internet-servicing-angular-components';
import { HttpCachingService } from './shared/service/http-caching-service/http-caching.service';
import { Subscription } from 'rxjs';
import { OauthClientService } from './shared/oauth-client.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
    // TODO canLoadApplication = false currently creates error in console:
    // core.js:4127 ERROR Error: ExpressionChangedAfterItHasBeenCheckedError:
    // Expression has changed after it was checked. Find work around so value
    // does not change from false to true before page is loaded.
    canLoadApplication = false;
    shouldHideWaitMessage = false;
    isLoadedByExternalApplication = false;
    skipOAuth = false;
    footer: FooterData;
    isLoadedByBAM = false;
    pingRenewalListener: Subscription;
    euaListener: Subscription;

    // eslint-disable-next-line max-params
    constructor(
        private router: Router,
        private readonly route: ActivatedRoute,
        private timeoutModalService: TimeoutModalService,
        private oauthEmitterService: OAuthEmitterService,
        private elementRef: ElementRef,
        private session: SessionService,
        private consumerConfigurerService: ConsumerConfigurerService,
        private personalBillingConfiguration: PersonalBillingConfiguration,
        private paymentFlowType: PaymentFlowType,
        private urlUtil: UrlUtil,
        private pingRedirectService: PingRedirectService,
        private readonly eventService: BillingEventService,
        private logger: LoggerService,
        private footerService: FooterService,
        private httpCachingService: HttpCachingService,
        private activatedRoute: ActivatedRoute,
        private oauthClientService: OauthClientService
    ) { }

    @HostListener('window:mousedown', ['$event'])
    @HostListener('window:mouseup', ['$event'])
    @HostListener('window:scroll', ['$event'])
    onSessionActivity(): void {
        this.timeoutModalService.recordSessionActivity();
    }

    @HostListener('window:click', ['$event'])
    handleClick(event: Event): void {
        const target = event && <HTMLElement>event.target;
        if (this.isExternalNavClick(target)) {
            const externalUrl: string = this.getExternalUrl(target);
            this.session.externalAppPageName = this.urlUtil.extractPageUrl(externalUrl);
            this.session.logOutUser();
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    leavingApp(): void {
        this.logger.info('Leaving ISB');
        this.logger.logCurrentContents();
        this.session.clearAccessToken();
        this.session.clearPageInfo();
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(): void {
        const goingTo = window.location.href;
        const comingFromBkey = this.route.snapshot.queryParams[environment.QUERY_PARAMS.billingAccount] || 'noBkey';
        this.navigateToNewBillingAccountCheck(goingTo, comingFromBkey);
    }

    ngOnInit(): void {
        this.logger.generateUniqueId();
        this.logger.info('App Initializing...');
        this.setBAMApplicationFlag();
        this.fetchFooter();
        if (this.pingRedirectService.isBackFromRedirect) {
            this.logger.info('isBackFromRedirect is true');
            this.pingRedirectService.handleBackFromRedirect();
        }
        this.setExternalApplicationFlag();
        this.loadNRScript();
        this.setQueryParamsToSession();
        if (this.isLoadedByExternalApplication) {
            this.consumerConfigurerService.setupTimeline(this.elementRef);
            if (this.isOauthRequired) {
                this.oauthEmitterService.oauthEmitter.subscribe((event: OAuthEvent) => {
                    if (!this.oauthEmitterService.inDescendantIframe()) {
                        this.reactTo(event);
                    }
                });
            } else {
                this.setCanLoadApplication(true);
            }
            this.router.navigateByUrl(URL_CONSTANT.BILLING_TIMELINE_ROUTE_URL);
        } else if (this.shouldSkipOAuth()) {
            this.skipOAuth = true;
            this.setCanLoadApplication(true);
        } else if (this.session.isBrowserBackAfterLogOut) {
            this.logger.info('Leaving ISB isBrowserBackAfterLogOut is true');
            this.session.gotoLogin();
        } else {
            const oAuthtoken = this.getUrlParameter('oauth_token');
            const mpStatus = this.getUrlParameter('mpstatus');
            if (oAuthtoken && mpStatus === 'success') {
                this.logger.info(`oAuthtoken exisits and mpStatus is successful`);
                sessionStorage.setItem('oAuthToken', oAuthtoken);
                const paymentUrl = `${window.location.origin}/${window.location.hash}`;
                window.location.replace(paymentUrl);
            } else {
                const authorizeOptions = {
                    client_id: environment.CLIENT_ID,
                    nonce: environment.OAUTH_CONFIG.nonce,
                    redirect_uri: `${location.protocol}//${location.host}${environment.OAUTH_REDIRECT_HANDLER_PATH}`,
                    response_type: environment.OAUTH_CONFIG.responseType,
                    scope: environment.OAUTH_CONFIG.scope,
                    state: 'no',
                    realm: environment.OAUTH_CONFIG.realm,
                    auth_method: environment.OAUTH_CONFIG.auth_method,
                    ttl: environment.OAUTH_CONFIG.ttl
                };
                this.callPingForRenewal(authorizeOptions);
            }
        }
        this.logger.logCurrentContents();
    }

    triggerEbiEvent(): void {
        return this.eventService.handleEvent(EBIEventType.ON_CLICK_OF_CONTACT_US_LINK_LOGS_OUT_OF_ISB);
    }

    setCanLoadApplication(value: boolean): void {
        this.logger.info(`Setting canLoadApplication to ${value}`);
        this.canLoadApplication = value;
        if (!this.shouldHideWaitMessage && value) {
            setTimeout(() => this.shouldHideWaitMessage = true, MILLISECONDS_IN_SECOND * 2);
        }
    }

    shouldSkipOAuth(): boolean {
        let shouldSkip = false;

        if (this.paymentFlowType.isQuickpay || (this.urlUtil.hashPath === '#/personal/billing/landing' && !this.pingRedirectService.isBackFromRedirect) || this.urlUtil.hashPath === '#/personal/stub/billing-stub') {
            shouldSkip = true;
        }

        return shouldSkip;
    }
    getExternalUrl(eventTarget: HTMLElement): string {
        return eventTarget && (<HTMLLinkElement>eventTarget).href;
    }

    navigateToNewBillingAccountCheck(goingTo, comingFrom): void {
        if (!goingTo.includes(comingFrom)) {
            this.refreshPage();
        }
    }

    refreshPage(): void {
        location.reload();
    }

    private loadNRScript(): void {
        // const script = document.createElement('script');
        // script.type = 'text/javascript';
        // script.src = environment.BAMNR;
        // document.getElementsByTagName('head')[0].appendChild(script);
    }

    private getUrlParameter(name: string): string {
        name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
        // eslint-disable-next-line prefer-template
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        const results = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    private reactTo(event): void {
        if (event.successful) {
            this.timeoutModalService.pollTimeOut();
            this.httpCachingService.checkAndRetrieveContextCache().subscribe({
                next: (contextCacheResponse) => {
                    if (contextCacheResponse?.context?.accountNumber) {
                        this.logger.info('Context cache retrieved successfully.');
                        const contextCacheAgreementNumber = contextCacheResponse.context.accountNumber.toString();
                        sessionStorage.setItem('BILLING_ACCOUNT_NUMBER', contextCacheAgreementNumber);
                        this.httpCachingService.fetchRetrieveCustomerAgreementResponse().subscribe((response) => {
                            const billingAccount = contextCacheAgreementNumber;
                            const sessionId = response.retrieveCustomerAgreementResponse?.return?.profile?.sessionId;
                            this.router.navigate([], {
                                queryParams: { pkey: response.retrieveCustomerAgreementResponse.return.profile.agreementGroup.agreement.agreementNumber },
                                queryParamsHandling: 'merge'
                            });
                            this.setA2ASessionIdAndBKey(billingAccount, sessionId);
                        });
                        this.setCanLoadApplication(true);
                        this.timeoutModalService.pollTimeOut();
                    } else {
                        this.setCanLoadApplication(true);
                        this.timeoutModalService.pollTimeOut();
                    }
                },
                error: (error) => {
                    this.logger.error('Error retrieving context cache.', error);
                    this.setCanLoadApplication(true);
                }
            });
        } else if (this.router.url.includes('/pay-bill/review')) {
            this.setCanLoadApplication(true);
        } else {
            this.router.navigateByUrl('/personal/error/system-down');
        }
    }

    private setA2ASessionIdAndBKey(billingAccount: string, sessionId: string): void {
        const params = { ...this.activatedRoute.snapshot.queryParams };
        if (!params.bkey) {
            params['bkey'] = billingAccount;
        }
        if (!params.myAccountUserSessionId) {
            params['myAccountUserSessionId'] = sessionId;
        }
        this.router.navigate([], { queryParams: params });
    }

    private isExternalNavClick(eventTarget: HTMLElement): boolean {
        if ((<HTMLLinkElement>eventTarget).href === environment.CONTACT_US_URL) {
            this.triggerEbiEvent();
        }
        return !!eventTarget &&
            !!eventTarget.getAttribute && (

                eventTarget.getAttribute('external') !== null ||

                isExternal((<HTMLLinkElement>eventTarget).href) && eventTarget.getAttribute('target') !== '_blank'

            );
    }

    private setExternalApplicationFlag(): void {
        const inputValue: string = this.elementRef.nativeElement.getAttribute('is-external-application');
        this.isLoadedByExternalApplication = inputValue && typeof inputValue === 'string' && inputValue === 'true';
    }

    private setBAMApplicationFlag(): void {
        this.isLoadedByBAM = window.location.href.includes('BAM/BillingTimeline');
    }

    get isOauthRequired(): boolean {
        return this.personalBillingConfiguration.isAccessTokenRequired;
    }

    private fetchFooter(): void {
        this.logger.info('Initializing footer');
        this.footer = this.footerService.defaultFooter(!this.paymentFlowType.isQuickpay);
        this
            .footerService
            .fetchFooter(!this.paymentFlowType.isQuickpay)
            .subscribe((footer) => {
                this.logger.info('Initializing footer complete');
                this.footer = footer;
            });
    }

    private callPingForRenewal(authorizeOptions): void {
        this.pingRenewalListener = this.oauthClientService.authorize(authorizeOptions, true).subscribe((renewEvent) => {
            if (renewEvent) {
                if (renewEvent.successful === false) {
                    this.logger.error('Renewing Ping Token failed');
                } else {
                    this.logger.info('Renewing Ping Token successful', { token: sessionStorage.getItem('access_token'), idToken: sessionStorage.getItem('id_token'), tokenDetails: sessionStorage.getItem('tokenDetails') });
                }
                if (this.pingRenewalListener) {
                    this.pingRenewalListener.unsubscribe();
                }
                this.callEUA(authorizeOptions);
            }
        });
    }

    private callEUA(authorizeOptions): void {
        this.euaListener = this.oauthClientService.authorize(authorizeOptions).subscribe((event) => {
            if (event?.authType === 'access') {
                if (event.successful === false) {
                    this.logger.error('Access token generation failed');
                } else {
                    this.logger.info('Access token generated successfully', { token: sessionStorage.getItem('accessToken'), idToken: sessionStorage.getItem('jsonWebToken'), tokenDetails: sessionStorage.getItem('tokenDetails') });
                    this.copyTokenDetails();
                }
                if (this.euaListener) {
                    this.euaListener.unsubscribe();
                }
                this.reactTo(event);
            }
        });
    }

    private copyTokenDetails(): void {
        if (window.sessionStorage.getItem('tokenDetails')) {
            window.sessionStorage.setItem('copyTokenDetails', window.sessionStorage.getItem('tokenDetails'));
        } else if (window.sessionStorage.getItem('tokenDetails') == null &&
            window.sessionStorage.getItem('copyTokenDetails')) {
            window.sessionStorage.setItem('tokenDetails', window.sessionStorage.getItem('copyTokenDetails'));
        }
    }

    private setQueryParamsToSession(): void {
        if (!environment.PRODUCTION && this.urlUtil.hashParams[environment.OAUTH_CONFIG.loginTokenParam]) {
            sessionStorage.setItem(environment.OAUTH_CONFIG.loginTokenSessionStorageLocation, this.urlUtil.hashParams[environment.OAUTH_CONFIG.loginTokenParam]);
        }
    }
}
