<section id="consider-this-section" [formGroup]="formGroup" *ngIf="toggleConsiderThis()">

    <div [ngClass]="{'bolt-space-bottom-lg' : (showEasyPay() || showSavePaymentMethod
		|| showSaveAsRefundComponent || showDueDate())}">
        <ng-container *ngIf="showEasyPay() && isMAPLandingPage">
            <div id="easy-pay-enroll-group" class="mb-12" aria-labelledby="easy-pay-enroll-label" class="consider-this-question">
                <b class="bolt-heading">Would you like to enroll in automatic payments?
                    <bw-bolt-contextual-help>
                        {{helpText.enrollInEasyPay}}
                    </bw-bolt-contextual-help>
                </b>
                <app-form-control-error id="easy-pay-enroll-error" *ngIf="easyPayRequiredErrorDisplayable">
                    <ul class="error-list">
                        <li>Yes or No is required.</li>
                    </ul>
                </app-form-control-error>
                <bolt-radio-group label="" ngDefaultControl required [attr.invalid]="easyPayRequiredErrorDisplayable ? true : null" name="enrollInEasyPay" id="enroll-in-ezpay"
                    formControlName="enrollInEasyPay" horizontal class="jm-visible">
                    <bolt-radio value="yes">Yes</bolt-radio>
                    <bolt-radio value="no">No</bolt-radio>
                </bolt-radio-group>
            </div>
        </ng-container>

        <ng-container *ngIf="showSavePaymentMethod &&
			!isSavedPaymentandRefundDisplayable()">
            <div id="save-payment-method-group" class="mb-12" aria-labelledby="save-payment-method-label" class="consider-this-question">
                <b class="bolt-heading">Save this payment method for the next time you log
                    in to pay your bill?
                    <bw-bolt-contextual-help>
                        {{helpText.savePaymentMethod}}
                    </bw-bolt-contextual-help>
                </b>
                <app-form-control-error id="save-payment-method-error" *ngIf="savePaymentMethodErrorDisplayable">
                    <ul class="error-list">
                        <li>Yes or No is required.</li>
                    </ul>
                </app-form-control-error>
                <bolt-radio-group label="" ngDefaultControl required [attr.invalid]="savePaymentMethodErrorDisplayable ? true : null" name="savePaymentMethod" id="save-payment-method"
                    formControlName="savePaymentMethod" horizontal class="jm-visible">
                    <bolt-radio value="yes">Yes</bolt-radio>
                    <bolt-radio value="no">No</bolt-radio>
                </bolt-radio-group>
            </div>
        </ng-container>

        <ng-container *ngIf="showSaveAsRefundComponent &&
			!isSavedPaymentandRefundDisplayable()">
            <app-save-as-refund-method formGroupName="saveAsRefundMethodGroup" (billingAccountsForRefund)="billingAccountsForRefund.emit($event)"></app-save-as-refund-method>
        </ng-container>

        <ng-container *ngIf="showEasyPay() && !isMAPLandingPage">
            <div id="easy-pay-enroll-group" class="mb-12" aria-labelledby="easy-pay-enroll-label" class="consider-this-question">
                <b class="bolt-heading">Would you like to enroll in automatic payments?
                    <bw-bolt-contextual-help>
                        {{helpText.enrollInEasyPay}}
                    </bw-bolt-contextual-help>
                </b>
                <app-form-control-error id="easy-pay-enroll-error" *ngIf="easyPayRequiredErrorDisplayable">
                    <ul class="error-list">
                        <li>Yes or No is required.</li>
                    </ul>
                </app-form-control-error>
                <bolt-radio-group label="" ngDefaultControl required [attr.invalid]="easyPayRequiredErrorDisplayable ? true : null" name="enrollInEasyPay" id="enroll-in-ezpay-affirmative"
                    formControlName="enrollInEasyPay" horizontal>
                    <bolt-radio value="yes">Yes</bolt-radio>
                    <bolt-radio value="no">No</bolt-radio>
                </bolt-radio-group>
            </div>
        </ng-container>

        <div *ngIf="showFutureAutoPayMethod && isAutomaticPaymentsEnrolled">
            <div id="easy-pay-enroll-group" class="mb-12" aria-labelledby="easy-pay-enroll-label" class="consider-this-question">
                <b class="bolt-heading">
                    Do you want this to be your new automatic payment method for future bills?
                </b>
                <app-form-control-error id="easy-pay-enroll-error" *ngIf="isWarnedFuturePayErrorDisplayable">
                    <ul class="error-list">
                        <li>Yes or No is required.</li>
                    </ul>
                </app-form-control-error>
                <bolt-radio-group label="" ngDefaultControl required [attr.invalid]="isWarnedFuturePayErrorDisplayable ? true : null" name="futureAutoPayment" (click)="futurePay()"
                    id="enroll-in-ezpay-affirmative" formControlName="futureAutoPayment" horizontal>
                    <bolt-radio value="yes">Yes</bolt-radio>
                    <bolt-radio value="no">No</bolt-radio>
                </bolt-radio-group>
            </div>
        </div>

        <div *ngIf="showDueDate() && getCurrentBillDueDate()">
            <div id="due-date-enroll-group" class="mb-12" aria-labelledby="due-date-enroll-label" class="consider-this-question">
                <b class="bolt-heading">
                    Your current due date is the {{getCurrentBillDueDate()}} of every month.
                    Would you like to change the day of the month when your bill is due?
                    <bolt-contextual-help>
                        <is-interpolate [text]="helpText.enrollInChangeDueDate"></is-interpolate>
                    </bolt-contextual-help>
                </b>
                <app-form-control-error id="due-date-change-enroll-error" *ngIf="dueDateChangeEnrollErrorDisplayable">
                    <ul class="error-list">
                        <li>Yes or No is required.</li>
                    </ul>
                </app-form-control-error>
                <bolt-radio-group label="" ngDefaultControl required [attr.invalid]="dueDateChangeEnrollErrorDisplayable ? true : null" name="enrollInChangeDueDate" id="enroll-in-change-due-date"
                    formControlName="enrollInChangeDueDate" horizontal>
                    <bolt-radio value="yes">Yes</bolt-radio>
                    <bolt-radio value="no">No</bolt-radio>
                </bolt-radio-group>
            </div>
        </div>
    </div>

    <div *ngIf="canSelectPaperlessBilling && !isSavedPaymentandRefundDisplayable()" [ngClass]="{'bolt-space-bottom-lg' : (paperlessEnrollmentSelected !==
		'yes')}">
        <section class="bolt-space-bottom-xl">
            <app-payment-sub-header>Paperless enrollment</app-payment-sub-header>
            <bw-bolt-notification type="info">
                <h5><strong>Paperless documentation for your Nationwide accounts.</strong></h5>
                <div>Receiving your statements via email is a perfect pairing to a digital
                    experience. By going
                    paperless with all your billing and policy documents, you choose a
                    sustainable and environmentally
                    friendly option.</div>
            </bw-bolt-notification>
        </section>
        <app-form-control-error id="easy-pay-enroll-error" *ngIf="paperlessEnrollErrorDisplayable">
            <ul class="error-list">
                <li>Yes or No is required.</li>
            </ul>
        </app-form-control-error>
        <fieldset class="section-content">
            <legend class="fields-only"></legend>
            <div id="paperlessEnrollGroup" class="mb-12" aria-labelledby="paperless-enroll-label">
                <bolt-radio-group label="Would you like to enroll in paperless for all of
					your accounts and policies?" ngDefaultControl required [attr.invalid]="paperlessEnrollErrorDisplayable ? true : null" name="enrollInPaperless" id="enroll-in-paperless"
                    (input)="paperlessEnrollment()" formControlName="enrollInPaperless" horizontal>

                    <bolt-radio value="yes">Yes</bolt-radio>
                    <bolt-radio value="no">No</bolt-radio>
                </bolt-radio-group>
            </div>
        </fieldset>
    </div>

</section>
<section [formGroup]="reviewFormGroup" *ngIf="toggleConsiderThis() && canSelectPaperlessBilling &&
	(paperlessEnrollmentSelected === 'yes')">
    <fieldset class="section-content">
        <legend class="fields-only"></legend>
        <div id="paperless-esdda-section" class="bolt-space-bottom-lg">
            <section id="your-contact-info">
                <app-email-form-control formGroupName="emailAddressGroup">
                </app-email-form-control>
            </section>
            <p class="bolt-space-bottom-md"><strong>Electronic Services and Document
                    Delivery Agreement (ESDDA)</strong>
            </p>
            <div class="esdda-scrollbox">
                <bw-html-interpolate content="{{ESDDAAutoForm}}">
                </bw-html-interpolate>
            </div>
            <div id="esdda-checkbox-plus-label" class="bolt-space-bottom-xl">
                <app-form-control-error id="esdda-checkbox-error" *ngIf="paperlessEsddaValidationError">
                    Please confirm that you have read and accept the ESDDA to complete your
                    enrollment in paperless.
                </app-form-control-error>
                <app-save-as-checkbox [labelFor]="'esdda-checkbox'" [bolt]="true">
                    <bolt-checkbox ngDefaultControl [attr.invalid]="paperlessEsddaValidationError ? 'Please confirm that you
						have read and accept the ESDDA to complete your enrollment in paperless.'
						: null" (change)="emitEsddaCheckedValue($event)" id="esdda-checkbox">
                        By checking this box, I confirm that I have read and accept the
                        terms and conditions in the Electronic Services and Document Delivery
                        Agreement.
                    </bolt-checkbox>
                </app-save-as-checkbox>
            </div>
        </div>
    </fieldset>
</section>