/* eslint-disable max-params */
import { environment as baseEnvironment, billingDetailsLink } from './environment.base';
import { ISBRouteParams } from '../app/shared/routing/isb-route.model';
import { BillingTab, BillingTabIcons } from '../app/shared/billing-tabs/billing-tab.model';

const PRODUCTION = false;

const APP_COOKIE = 'Internet Servicing Billing';

const CLIENT_ID = 'BfW68ymVlBwtWnlaHwtzeq8YkRUkJlaG';

const MAKE_A_PAYMENT = false;

const LOG_OUT_URL = 'https://myaccount.nationwide.com/myaccount/Logout.action';

const billsAndPaymentsPath = '/personal/billingMigration/bills-and-payments';
const questionsAndTipsPath = '/personal/billingMigration/questions-and-tips';
const paymentPreferencesPath = '/personal/billingMigration/payment-preferences';
const billingDetailsPath = '/personal/billing/details';
const billingTimelinePath = '/personal/billing/timeline';
const paymentPreferences_redirectToICP = true;
const CYBERLIFE_QUICKPAY_ENABLED = true;

// const payBillLandingPath = '/personal/pay-bill/landing';
// const payBillReviewPath = '/personal/pay-bill/review';
// const payBillConfirmationPath = '/personal/pay-bill/confirmation';

const EUA_API_HOST = 'https://api-stage.nationwide.com';
const CONNECTION_TYPE = 'CIB1';
const IS_CONNECTION_TYPE_REQUIRED = true;
const OAUTH_CONFIG = {
    ...baseEnvironment.OAUTH_CONFIG,
    ...{
        oauthEndpoint: `${EUA_API_HOST}/security-processing/enterprise-user-auth/v2/authorize`,
        client_id: CLIENT_ID,
        debug: false,
        useValidator: false
    }
};

const ADOBE_MBOXES = {
    PAPERLESS_BILLING: 'ab-paperless-billing-mbox',
    INCREASE_OVERPAYMENT: 'ab-increase-overpayment-mbox',
    PAYMENT_HISTORY: 'ab-add-payment-history-to-map',
    CONVERT_QUICKPAY_TO_REFT_V2: 'ab-convert-quickpay-reft-v2'
};

const PERSONAL_BILLING_API_HOST = 'https://api-stage.nationwide.com';
const NATIONWIDE_HOMEPAGE = 'https://www.nationwide.com';
const DPIM_AGENCY_INFORMATION_URL_STATIC_PARAMS = `legacyIdentifierType=Legacy Individual Role Number&legacyAdministrativeSystem=AIMS`;

const SMART_MILES = {
    ENDPOINTS: {
        TOKEN: (ctToken: string): string =>
            `https://sre.nationwide.com/smartmiles?NWIESESSION=${ctToken}`
    }
};

const ISC = {
    ENDPOINTS: {
        iscLandingUrl: (contextId: string): string => `https://stage-isc.nwservicecenter.com/iApp/isc/app/deepLinkAuthorization.action?context_id=${contextId}`
    }
};

const PERSONAL_BILLING_ROOT = '/billing-collections/personal-lines-billing/v2';
const BILLING = {
    PATHS: {
        bills: (account): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/bills`,
        relatedPolicies: (account): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/related-policies`,
        policiesEvents: (account): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/policy-events`,
        transactions: (account): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/transactions`,
        billingAccount: (account): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}`,
        payPlan: (account, method): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/pay-plans/current?request_type=${method}`,
        zeroAmountDue: (account): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/balances`,
        patchPayPlan: (account): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/pay-plans/current`,
        billCalculationInquiry: (account): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/$metadata/bill-calculator?requestMethod=inquiry`,
        billingDueDates: (account): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/$metadata/due-dates`,
        refundStatuses: (account, method): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/refunds?refundMethod=${method}`
    },
    ENDPOINTS: {
        savedPaymentMethodsUrl: (ecn): string => `https://api-stage.nationwide.com/billing-collections/money-processing/v1/saved-payment-methods?enterpriseCustomerNumber=${ecn}`,
        singleSavedPaymentMethodUrl: (payMethodId: string): string => `https://api-stage.nationwide.com/billing-collections/money-processing/v1/saved-payment-methods/${payMethodId}`,
        retrieveBank: (routingNumber): string => `https://api-stage.nationwide.com/billing-collections/money-processing/v1/banks/${routingNumber}`,
        saveNewPayMethodUrl: (ecn, accountNumber): string => `https://apipci-stage.nationwide.com/billing-collections/money-processing/v1/saved-payment-methods?enterpriseCustomerNumber=${ecn}&billingAccountNumber=${accountNumber}`,
        billingAccountPaymentMethodUrl: (accountNumber): string => `https://apipci-stage.nationwide.com/billing-collections/money-processing/v1/personal-lines-accounts/${accountNumber}/billing-account-paymentmethod`,
        updatePayMethodUrl: (payMethodId: string): string => `https://apipci-stage.nationwide.com/billing-collections/money-processing/v1/saved-payment-methods/${payMethodId}`,
        bills_url: (account): string => {
            // TODO: Revert this back to normal bills url for monitoring account once billing makes fix for billType=FUTURE
            const monitoringAccountNumber = '9278642348';
            let url = `${PERSONAL_BILLING_API_HOST}/billing-collections/personal-lines-billing/v2/billing-accounts/${account}/bills`;
            if (account === monitoringAccountNumber) {
                url += '?billType=PRIOR';
            }
            return url;
        },

        related_policies: (account): string => `${PERSONAL_BILLING_API_HOST}${BILLING.PATHS.relatedPolicies(account)}`,

        policies_events: (account): string => `${PERSONAL_BILLING_API_HOST}${BILLING.PATHS.policiesEvents(account)}`,

        transactions: (account): string => `${PERSONAL_BILLING_API_HOST}${BILLING.PATHS.transactions(account)}`,

        billing_account: (account): string => `${PERSONAL_BILLING_API_HOST}${BILLING.PATHS.billingAccount(account)}`,

        money_processing_personal_payments_url: (account): string => `https://apipci-stage.nationwide.com/billing-collections/money-processing/v1/personal-lines-accounts/${account}/payments`,

        payPlanUrl: (account, method): string => `${PERSONAL_BILLING_API_HOST}${BILLING.PATHS.payPlan(account, method)}`,

        zero_amount_due_url: (account): string => `${PERSONAL_BILLING_API_HOST}${BILLING.PATHS.zeroAmountDue(account)}`,

        patch_pay_plan: (account): string => `${PERSONAL_BILLING_API_HOST}${BILLING.PATHS.patchPayPlan(account)}`,

        bill_calculation_inquiry: (account): string => `${PERSONAL_BILLING_API_HOST}${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/$metadata/bill-calculator?requestMethod=inquiry`,

        retrieve_due_dates: (account): string => `${PERSONAL_BILLING_API_HOST}${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/$metadata/due-dates`,
        update_due_dates: (account): string => `${PERSONAL_BILLING_API_HOST}${PERSONAL_BILLING_ROOT}/billing-accounts/${account}`,

        refund_statuses: (account, method): string => `${PERSONAL_BILLING_API_HOST}${BILLING.PATHS.refundStatuses(account, method)}`,

        refunds: `https://api-stage.nationwide.com/billing-collections/covid19refunds/v1/refunds/me`,

        retrieve_customer_billing_accounts_url: (ecn): string => `${PERSONAL_BILLING_API_HOST}${PERSONAL_BILLING_ROOT}/customer-billing-accounts/${ecn}`
    },
    REGION: null
};

const MASTERPASS_ENABLED = true;
const MASTERPASS_ROOT = 'https://api.mastercard.com/masterpass';
const MASTERPASS_ISP_ROOT = 'https://api-stage.nationwide.com/policymanagement/internetservicing-account/v1';
const WALLETPAY = {
    ENDPOINTS: {
        masterpassEncryptDataUrl: (account, checkoutId, cartId): string => `${MASTERPASS_ROOT}/encrypted-paymentdata/${account}?checkoutId=${checkoutId}&cartId=${cartId}`,
        masterpassPostbackUrl: (): string => `${MASTERPASS_ROOT}/postback`,
        masterpassISPEncryptDataUrl: (clientId): string => `${MASTERPASS_ISP_ROOT}/encryptedPaymentData-secure?client_id=${clientId}`,
        masterpassISPPostbackUrl: (clientId): string => `${MASTERPASS_ISP_ROOT}/postback-secure?client_id=${clientId}`
    }
};

const ECIM_ROOT = 'https://api-stage.nationwide.com/customer-information-management/enterprise-customer/v1';
const ECIM = {
    DEPTH: '1',
    URL: {
        patchInternetRegistrationsUrl: (guid: string, depth: string, clientId: string): string =>
            `${ECIM_ROOT}/internetregistrations/${guid}?depth=${depth}&apikey=${clientId}`,
        getInternetRegistrationsUrl: (guid: string, clientId: string): string =>
            `${ECIM_ROOT}/internetregistrations/${guid}?apikey=${clientId}`,
        getAgreementUrl: (accountNumber: string, clientId: string, sourceSystem: string): string =>
            `${ECIM_ROOT}/agreements/${sourceSystem}/${accountNumber}?apikey=${clientId}`,
        patchCustomersUrl: (ecn: string, clientId: string): string =>
            `${ECIM_ROOT}/customers/${ecn}?apikey=${clientId}`,
        patchAgreementRolesUrl: (accountNumber: string, clientId: string, sourceSystem: string, roleIdentifier: string): string =>
            `${ECIM_ROOT}/agreements/${sourceSystem}/${accountNumber}/agreementroles/${roleIdentifier}?apikey=${clientId}`
    }
};

const PBS = {
    ...baseEnvironment.PBS,
    ...{
        ENDPOINTS: {
            RCA: `https://api-stage.nationwide.com/accountagreementmanagement/portfoliobusinessservice/v1/customeragreement`,
            RCAWR: `https://api-stage.nationwide.com/accountagreementmanagement/portfoliobusinessservice/v1/customeragreementwithrefresh`,
            RCP: 'https://api-stage.nationwide.com/accountagreementmanagement/portfoliobusinessservice/v1/customerportfolio',
            SEARCH_AGREEMENT: 'https://api-stage.nationwide.com/accountagreementmanagement/portfoliobusinessservice/quick-auth/v1/searchAgreement',
            SEARCH_PHONE_NUMBER: 'https://api-stage.nationwide.com/accountagreementmanagement/portfoliobusinessservice/quick-auth/v1/searchPhone',
            SPLIT_PARTY_RCA: `https://api-stage.nationwide.com/accountagreementmanagement/portfoliobusinessservice/split-parties/v1/customeragreementwithrefresh`
        },

        HEADERS: {
            AUTH: `Basic ${CLIENT_ID}`
        }
    }
};

const NFCIM = {
    cimEndpoint: (agreementNumber): string =>
        `https://api-stage.nationwide.com/policy-management/nf-customer-information-management/v1/customerprofiles/${agreementNumber}-CYBERLIFE`,

    HEADERS: {
        AUTH: `Basic ${CLIENT_ID}`
    }
};

const NW_HEADER = {
    logout: LOG_OUT_URL,
    personal_information: 'https://myaccount.nationwide.com/myaccount/profile/RetrieveUserContactInformation.action',
    preferences: 'https://myaccount.nationwide.com/myaccount/preferences/RetrieveUserContactPreferences.action',
    manageAuthorizedUsers: 'https://myaccount.nationwide.com/myaccount/preferences/RetrieveManageAuthorizedUsers.action',
    contactUs: 'https://myaccount.nationwide.com/myaccount/portfolio/RetrieveContactDirectory.action',
    unauthenticatedContactUs: 'https://www.nationwide.com/personal/contact/',
    allAccounts: 'https://myaccount.nationwide.com/myaccount/index.jsp',
    navigationLinks: ({ accountNumber, sessionId }: ISBRouteParams = {}): any => [
        {
            text: 'Billing Details',
            url: `${billingDetailsPath}?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}`
        },
        {
            text: 'Bills & Payments',
            url: `${billsAndPaymentsPath}?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}`
        },
        {
            text: 'Payment Preferences',
            url: `${paymentPreferencesPath}?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}`
        },
        {
            text: 'Questions & Tips',
            url: `${questionsAndTipsPath}?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}`
        },
        {
            text: 'Contact Us',
            url: 'https://myaccount.nationwide.com/myaccount/portfolio/RetrieveContactDirectory.action'
        }
    ],
    navigationLinksNonOwner: [
        {
            text: 'Contact Us',
            url: 'https://myaccount.nationwide.com/myaccount/portfolio/RetrieveContactDirectory.action'
        }
    ]
};

const CAM_LINK = 'https://st-myaccount.nationwide.nwie.net/myaccount/index.jsp';

export const paymentPreferencesLink = (routeParams: string): string => `/#${paymentPreferencesPath}?${routeParams}`;

const BREAD_CRUMBS = {
    billing_details: [{
        name: 'Billing Details',
        link: CAM_LINK
    }],
    billing_picker: [{
        name: 'Billing Picker',
        link: CAM_LINK
    }],
    CYBERLIFE_PAY_BILL: [{
        name: 'Make A Payment',
        link: CAM_LINK
    }],
    PAY_BILL: ({ accountNumber, sessionId }: ISBRouteParams = {}): any =>
        [{
            name: 'Billing Details',
            link: baseEnvironment.ISB.HOST_URL + billingDetailsLink({ accountNumber, sessionId })
        }],
    CHANGE_DUE_DATE: ({ accountNumber, sessionId }: ISBRouteParams = {}): any =>
        [{
            name: 'Billing Details',
            link: baseEnvironment.ISB.HOST_URL + billingDetailsLink({ accountNumber, sessionId })
        }],
    MANAGE_PAYMENT_PREFERENCES: ({ accountNumber, sessionId }: ISBRouteParams = {}): any =>
        [{
            name: `Billing Details`,
            link: baseEnvironment.ISB.HOST_URL + billingDetailsLink({ accountNumber, sessionId })
        }],
    MAKE_ANOTHER_PAYMENT: [{
        name: 'All Accounts',
        link: CAM_LINK
    }]
};

const BILLING_NAVIGATION_TABS = [
    {
        text: 'Billing details',
        url: (url: ISBRouteParams = {}): string => window.location.href,
        active: true
    },
    {
        text: 'Bills & payments',
        url: ({ accountNumber, sessionId }: ISBRouteParams = {}): string =>
            `/#${billsAndPaymentsPath}?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}`,
        active: false
    },
    {
        text: 'Payment preferences',
        url: ({ accountNumber, sessionId }: ISBRouteParams = {}): string =>
            `/#${paymentPreferencesPath}?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}`,
        active: false
    },
    {
        text: 'Questions & tips',
        url: ({ accountNumber, sessionId }: ISBRouteParams = {}): string =>
            `/#${questionsAndTipsPath}?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}`,
        active: false
    }
];

const BILLING_MIGRATION_TABS = [
    new BillingTab('Billing details',
        ({ accountNumber, sessionId }: ISBRouteParams) =>
            `${billingTimelinePath}?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}&appName=ISB`,
        true,
        BillingTabIcons.BILL
    ),
    new BillingTab(
        'Bills & payments',
        ({ accountNumber, sessionId }: ISBRouteParams) =>
            `${billsAndPaymentsPath}?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}`,
        true,
        BillingTabIcons.DOLLAR_SIGN
    ),
    new BillingTab(
        'Payment preferences',
        ({ accountNumber, sessionId }: ISBRouteParams) =>
            `${paymentPreferencesPath}?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}`
        ,
        true,
        BillingTabIcons.GEARS
    ),
    new BillingTab(
        'Questions & tips',
        ({ accountNumber, sessionId }: ISBRouteParams) =>
            `${questionsAndTipsPath}?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}`,
        true,
        BillingTabIcons.QUESTION_MARK)
];

const NW_FOOTER = {
    forBusinessLink: 'https://www.nationwide.com/small-business-insurance.jsp',
    contactUsLink: 'https://myaccount.nationwide.com/myaccount/portfolio/RetrieveContactDirectory.action',
    unauthenticatedContactUsLink: 'https://www.nationwide.com/personal/contact/',
    logout: LOG_OUT_URL
};

const TIMEOUT = {
    ...baseEnvironment.TIMEOUT,
    ...{
        sessionLogOutUrl: 'https://www.nationwide.com/access/web/sessionExpired.htm',
        logOutUrl: LOG_OUT_URL,
        logInUrl: 'https://login.nationwide.com/access/web/login.htm'
    }
};

const LOGGING_API_HOST = 'https://api-stage.nationwide.com';
const LOGGING = {
    ...baseEnvironment.LOGGING,
    clientLoggingEnabled: true,
    clientId: CLIENT_ID,
    endpoint: `${LOGGING_API_HOST}/it-management/client-logging/v1/client-logs`,
    envName: 'staging',
    pushLength: 12

};

const MOBIUS_DOCUMENT_MANAGEMENT = {
    documentSearchUrl: 'https://api-stage.nationwide.com/communication/mobiusviewapi/v1/search'
};

const DOCUMENTS = {
    apigeeEnvironment: 'https://api-stage.nationwide.com/',
    mobiusEndpoint: 'communication/mobiusdocumentmanagement/v1/documents/',
    repoId: 'RDCPROD',
    docusignApiEndpoint: 'policy-management/servicing-docusign-experience/v1/',
    filenetEndpoint: 'document-repository/filenetdocumentmanagement/v1/documents/',
    consumerId: 'ESIG',
    search: '#documents',
    documentOpenerEndpoint: 'policymanagement/open-documents/v1/document'
};

const NW_NAV_LIFE_LINK = {

    navigationLinksForLife: (routeParams: ISBRouteParams): any => [
        {
            text: 'Bills & Payments',
            url: `${billsAndPaymentsPath}?bkey=${routeParams.accountNumber}&myAccountUserSessionId=${routeParams.sessionId}`
        },
        {
            text: 'Payment Preferences',
            url: `${paymentPreferencesPath}?bkey=${routeParams.accountNumber}&myAccountUserSessionId=${routeParams.sessionId}`
        },
        {
            text: 'Questions & Tips',
            url: `${questionsAndTipsPath}?bkey=${routeParams.accountNumber}&myAccountUserSessionId=${routeParams.sessionId}`
        },
        {
            text: 'Contact Us',
            url: 'https://myaccount.nationwide.com/myaccount/portfolio/RetrieveContactDirectory.action'
        }
    ],
    navigationLinksForCyberLife: (routeParams: ISBRouteParams): any => [
        {
            text: 'Contact Us',
            url: 'https://myaccount.nationwide.com/myaccount/portfolio/RetrieveContactDirectory.action'
        }
    ]
};

const INTERNET_SERVICING_POLICY_ROOT = 'https://api-stage.nationwide.com/policymanagement/internetservicing-account/v1';
const ISP = {
    ENDPOINTS: {
        BILLS_AND_PAYMENTS: (accountNumber: string, clientId: string, startDate: string, endDate: string): string => `${INTERNET_SERVICING_POLICY_ROOT}/accounts/${accountNumber}/billsAndPayments?client_id=${clientId}&start_date=${startDate}&end_date=${endDate}`,
        RETREIVE_PAYMENT_HISTORY: (accountNumber, policyNumbers: string, clientId, startDate, endDate): string =>
            `${INTERNET_SERVICING_POLICY_ROOT}/accounts/${accountNumber}/payment-history` +
            `?${policyNumbers}` +
            `&client_id=${clientId}` +
            `&start_date=${startDate}&end_date=${endDate}`,
        PAYMENT_CONFIRMATION_EMAIL_URL: (clientId: string): string => `${INTERNET_SERVICING_POLICY_ROOT}/accounts/sendPaymentConfirmationEmail?client_id=${clientId}`,
        CHANGE_DUE_DATE_CONFIRMATION_EMAIL_URL: (clientId: string): string => `${INTERNET_SERVICING_POLICY_ROOT}/accounts/sendChangeDueDateConfirmationEmail?client_id=${clientId}`
    }
};

const EBI = {
    ebiEndPoint: 'https://api-stage.nationwide.com/businessintelligence/enterprisebusinessintelligence/v1/log'
};

const CTM = {
    ctmEndPoint: 'https://api-stage.nationwide.com/customerinformationmanagement/contactmanagement/v1/interactions'
};

const DOCUMENT_OPENER = {
    endpoint: 'https://api-stage.nationwide.com/billing-collections/internet-servicing-billing/v1/open-document'
};

const ZERO_AMOUNT_DUE_API_SERVICE = {
    isEnabled: true
};

const CLICK_TO_CHAT = {
    enabled: false
};

const CARD_ENCRYPTION = {
    url: 'https://payments2-pt.nationwide.com/paymentCard/encrypted/4.0'

};

const ONE_TIME_PASSWORD = {
    url: 'https://api-stage.nationwide.com/security-processing/one-time-password/v1/tokens'
};

const MAKE_PAYMENT_CANCEL_BUTTON_LINK = (routeParameters: ISBRouteParams, isQuickpay: boolean, canAccessBillingPages: boolean): any => {
    let url;

    if (isQuickpay) {
        url = NATIONWIDE_HOMEPAGE;
    } else {
        url = canAccessBillingPages ? `${billsAndPaymentsPath}?bkey=${routeParameters.accountNumber}&myAccountUserSessionId=${routeParameters.sessionId}` : CAM_LINK;
    }

    return url;
};

const ACCOUNT_VALIDATION = {
    url: 'https://api-stage.nationwide.com/billing-collections/bank-account-verification/v1/bank-accounts/verify',
    featureToggle: true
};
const CYBERLIFE_ACCOUNT_LEVEL_PAYMENTS = {
    enabled: true
};

const BAMNR = 'assets/js/bam-nr/sb-pd-bamnr.js';

const COMMERCIAL_QUICKPAY_URL = 'https://commercialservicing.nationwide.com/commercial/#/quickPay/search';

const CYBERLIFE_QUICKPAY = {
    eBISourceSystemFieldLengthConditionEnabled: true
};

const CONTEXT_ISB_EXP_API = {
    contextUrl: (): string => 'https://api-stage.nationwide.com/security-processing/context-cache/v1/contexts'
};

const ISB_EXP_API_BASE_URL = 'https://api-stage.nationwide.com/billing-collections/internet-servicing-billing/v1';
const ISB_EXP_API = {
    moneyProcessing: {
        singleSavedPaymentMethodUrl: (methodId: string): string => `${ISB_EXP_API_BASE_URL}/money-processing/saved-payment-methods/${methodId}`,
        savedPaymentMethodsUrl: (): string => `${ISB_EXP_API_BASE_URL}/money-processing/saved-payment-methods`,
        paymentsUrl: (accountNumber: string): string => `${ISB_EXP_API_BASE_URL}/money-processing/personal-lines-accounts/${accountNumber}/payments`,
        banksUrl: (routingNumber: string): string => `${ISB_EXP_API_BASE_URL}/money-processing/banks/${routingNumber}`,
        cancelScheduledPaymentUrl: (agreementNumber: string, paymentId: string): string => `${ISB_EXP_API_BASE_URL}/money-processing/personal-lines-accounts/${agreementNumber}/payments/${paymentId}`
    },
    personalLinesBilling: {
        billsUrl: (accountNumber: string): string => `${ISB_EXP_API_BASE_URL}/personal-lines-billing/billing-accounts/${accountNumber}/bills`,
        payPlanUrl: (accountNumber: string, requestType?: string): string =>
            `${ISB_EXP_API_BASE_URL}/personal-lines-billing/billing-accounts/${accountNumber}/pay-plans/current?request_type=${requestType || ''}`,
        eligiblePayPlanUrl: (accountNumber: string): string => `${ISB_EXP_API_BASE_URL}/personal-lines-billing/billing-accounts/${accountNumber}/eligible-payplan`,
        calculateDownPaymentUrl: (): string => `${ISB_EXP_API_BASE_URL}/personal-lines-billing/billing-accounts/1/down-payment`,
        agreementUrl: (guid: string): string => `${ISB_EXP_API_BASE_URL}/personal-lines-billing/billing-accounts/${guid}/prefill`
    },
    customerEmails: {
        sendEnrollAutomaticPaymentsEmailUrl: (): string => `${ISB_EXP_API_BASE_URL}/customer-emails/automatic-payments-enroll`,
        sendEditAutomaticPaymentsEmailUrl: (): string => `${ISB_EXP_API_BASE_URL}/customer-emails/automatic-payments-edit`,
        sendCancelAutomaticPaymentsEmailUrl: (): string => `${ISB_EXP_API_BASE_URL}/customer-emails/automatic-payments-cancel`,
        sendRemoveRefundMethodEmailUrl: (): string => `${ISB_EXP_API_BASE_URL}/customer-emails/remove-refund-method`,
        sendAddRefundMethodEmailUrl: (): string => `${ISB_EXP_API_BASE_URL}/customer-emails/add-refund-method`,
        sendSuspendAutomaticPaymentsEmailUrl: (): string => `${ISB_EXP_API_BASE_URL}/customer-emails/suspend-recurring-payment`,
        sendAddSavedPaymentMethodEmailUrl: (): string => `${ISB_EXP_API_BASE_URL}/customer-emails/add-saved-payment-method`,
        sendPaymentConfirmationEmailUrl: (): string => `${ISB_EXP_API_BASE_URL}/customer-emails/sendPaymentConfirmationEmail`,
        sendChangeDueDateConfirmationEmailUrl: (): string => `${ISB_EXP_API_BASE_URL}/customer-emails/change-due-date`
    },
    contexts: {
        contextsUrl: (ecn: string): string => `${ISB_EXP_API_BASE_URL}/contexts/${ecn}`
    }
};

const CONTEXT_CACHE = {
    contextsUrl: (): string => 'https://api-stage.nationwide.com/security-processing/context-cache/v1/contexts/'
};

const CHASE_PROFILE_CREATION_URL = 'https://api-stage.nationwide.com/billing-collections/chase-profile-creation/v1/payment-card-profiles';

const PLAID = {
    url: 'https://api-stage.nationwide.com/operations-management/internet-servicing/v1/plaid/exchangeToken',
    env: 'stage',
    public_key: 'fe9cc1c22b9a69c596ad1e19a2bac4'
};
const AGENCY_INFORMATION = {
    url: (agreementNumber: string): string => `https://api-stage.nationwide.com/billing-collections/personal-lines-billing/v2/billing-accounts/${agreementNumber}/accountagencyinformation`
};
const DISTRIBUTED_DOCUMENTS = {
    url: 'https://api-stage.nationwide.com/documentation-management/distributed-documents/v1/documents?',
    targetEnvironment: 'PROD'
};
const DPIM_AGENCY_INFORMATION = {
    url: (agentNumber: string): string => `https://api-stage.nationwide.com/channel-distribution-partner-management/distribution-partner-management/v1/legacies/${agentNumber}/namecontactpoints?${DPIM_AGENCY_INFORMATION_URL_STATIC_PARAMS}`
};
const GETDISTRIBUTIONPARTNER_INFO = {
    url: (agentIdentifier: string, query: string): string => `https://api-stage.nationwide.com/operations-management/internet-servicing/v1/distribution-partner-management/distributionpartnerroles/${agentIdentifier}?${query}`
};

// FOLLOWING CONSTANT MUST BE LOWERCASE FOR TRIDION SERVICE TO CONFIGURE
const tridion = {
    endpoint: '/prod/preview/tridion'
};

const CAM_CONTACT_US = {
    url: 'https://myaccount.nationwide.com/myaccount/portfolio/RetrieveContactDirectory.action'
};

const WAYS_TO_PAY = {
    ...baseEnvironment.WAYS_TO_PAY,
    url: 'https://ways2pay-pt.nationwide.com'
};

const overrides = {
    APP_COOKIE,
    PRODUCTION,
    CLIENT_ID,
    ADOBE_MBOXES,
    MAKE_A_PAYMENT,
    LOG_OUT_URL,
    OAUTH_CONFIG,
    BILLING,
    PBS,
    NW_HEADER,
    BREAD_CRUMBS,
    BILLING_NAVIGATION_TABS,
    NW_FOOTER,
    TIMEOUT,
    LOGGING,
    MOBIUS_DOCUMENT_MANAGEMENT,
    SMART_MILES,
    BILLING_MIGRATION_TABS,
    DOCUMENTS,
    NW_NAV_LIFE_LINK,
    ISP,
    EBI,
    CTM,
    DOCUMENT_OPENER,
    CONNECTION_TYPE,
    IS_CONNECTION_TYPE_REQUIRED,
    ZERO_AMOUNT_DUE_API_SERVICE,
    NFCIM,
    CLICK_TO_CHAT,
    ECIM,
    CAM_HOME: CAM_LINK,
    MAKE_PAYMENT_CANCEL_BUTTON_LINK,
    CARD_ENCRYPTION,
    ONE_TIME_PASSWORD,
    ACCOUNT_VALIDATION,
    WALLETPAY,
    CYBERLIFE_ACCOUNT_LEVEL_PAYMENTS,
    MASTERPASS_ENABLED,
    PERSONAL_BILLING_API_HOST,
    EUA_API_HOST,
    LOGGING_API_HOST,
    paymentPreferences_redirectToICP,
    BAMNR,
    CYBERLIFE_QUICKPAY_ENABLED,
    COMMERCIAL_QUICKPAY_URL,
    CYBERLIFE_QUICKPAY,
    ISB_EXP_API_BASE_URL,
    ISB_EXP_API,
    CHASE_PROFILE_CREATION_URL,
    PLAID,
    tridion,
    AGENCY_INFORMATION,
    DISTRIBUTED_DOCUMENTS,
    DPIM_AGENCY_INFORMATION,
    GETDISTRIBUTIONPARTNER_INFO,
    ISC,
    CONTEXT_ISB_EXP_API,
    WAYS_TO_PAY,
    CONTEXT_CACHE
};

export const environment = { ...baseEnvironment, ...overrides };
