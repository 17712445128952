<div *ngIf="!selectedPaymentMethod?.isPlaceholder" class="selected-payment-method-label">
    <div class="payment-method-icon-margin">
        <img [ngClass]="{'bank-account-icon': selectedPaymentMethod?.isBankAccount}" [src]="getPaymentMethodImageSrc()" [alt]="getPaymentMethodImageAltText()" />
    </div>
    <div class="selected-payment-method-details" id="selectedPaymentMethodDetails">
        <div class="bolt-body-copy-lg">
            {{selectedPaymentMethod?.getWaysToPayMethodLabel()}}
        </div>
        <div *ngIf="selectedPaymentMethod?.isBankCard" class="bolt-body-copy-sm">
            Exp {{selectedPaymentMethod?.bankCardExpirationDate}}
        </div>
    </div>
</div>
<bolt-button id="open-ways-to-pay" (click)="openWaysToPayModal()" class="jm-visible select-pay-method-button bolt-space-bottom-lg" [class]="'jm-visible'"
    [ngClass]="{'select-pay-method-button-margin': !selectedPaymentMethod?.isPlaceholder}">
    {{selectPayMethodButtonText}}
</bolt-button>
