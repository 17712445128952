
import { Component, Input, Inject } from '@angular/core';
import { BaseFormGroupComponent } from '../base-form-control/base-form-control.component';
import { ControlContainer } from '@angular/forms';
import { LoggerService } from '../../logger/logger.service';
import { environment } from '../../../../environments/environment';
import { DatePipe } from '@angular/common';
import { PayBillLandingService } from '../../../pay-bill/pay-bill-landing/pay-bill-landing.service';

export type ControlNames = 'accountNumberGroup' | 'accountTypeGroup' | 'bankAccountInfo';

@Component({
    selector: 'app-bank-account-info-plaid',
    templateUrl: './bank-account-info-plaid.component.html',
    styleUrls: ['./bank-account-info-plaid.component.scss']
})

export class BankAccountInfoPlaidComponent extends BaseFormGroupComponent<ControlNames> {
    @Input() readonly: boolean;
    routingNumber: string;
    accountNumber: string;
    accountType: string;
    plaidResponse = false;
    publicKey = environment.PLAID.public_key;
    env = environment.PLAID.env;

    handler: LinkHandler;

    // eslint-disable-next-line max-params
    constructor(
        controlContainer: ControlContainer,
        @Inject('payBillLandingService') private payBillLandingService: PayBillLandingService,
        @Inject('logger') private logger: LoggerService,
        private datePipe: DatePipe,

        @Inject('plaid') private plaid: PlaidLink
    ) {
        super(controlContainer);
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const bankAccountInfoPlaidComponent = this;
        this.handler = this.plaid.create({
            clientName: 'Nationwide',
            env: bankAccountInfoPlaidComponent.env,
            key: bankAccountInfoPlaidComponent.publicKey,
            product: ['auth'],

            onLoad() { },

            onSuccess(public_token, metadata) {
                bankAccountInfoPlaidComponent.payBillLandingService
                    .getTokenExchange(public_token, metadata.account.id)
                    .subscribe((response) => {
                        for (const accountDetails of response.numbers.ach) {
                            if (accountDetails.account_id === metadata.account.id) {
                                bankAccountInfoPlaidComponent.routingNumber = response.numbers.ach[0].routing;
                                bankAccountInfoPlaidComponent.accountNumber = response.numbers.ach[0].account;
                                if (response.accounts[0].subtype === 'checking') {
                                    bankAccountInfoPlaidComponent.accountType = 'Checking';
                                }
                                if (response.accounts[0].subtype === 'savings') {
                                    bankAccountInfoPlaidComponent.accountType = 'Savings';
                                }
                                bankAccountInfoPlaidComponent.plaidResponse = true;
                            }
                        }
                    });
            },
            onExit(err) {
                // The user exited the Link flow.
                if (err != null) {
                    bankAccountInfoPlaidComponent.logError(err);
                }
            }
        });
    }

    logError(error): void {
        this.logger.info('user exited plaid because of error occurred', {
            processingDate: this.datePipe.transform(new Date(), 'MM/dd/yyyy'),
            error_type: error.error_type,
            error_message: error.error_message,
            error_code: error.error_code,
            display_message: error.display_message
        });
        this.logger.logCurrentContents();
    }

    onPlaidClick(event): void {
        event.preventDefault();
        event.stopPropagation();
        this.linkHandler();
    }

    get accountNumberGroupErrorDisplayable(): boolean {
        return this.errorsDisplayable('accountNumberGroup');
    }

    linkHandler(): void {
        this.handler.open();
    }
}
