import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { of, catchError, map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LoggerService } from '../../logger/logger.service';
import { SessionService } from '../../session/session.service';
import { BillingApiCommonService } from '../billing-api-common.service';
import {
    CtmEventType,
    CtmEventBody,
    CtmEventData,
    CtmProductType,
    CtmProductTypeMap,
    ProductType
} from './ctm-model';
import { DatePipe } from '@angular/common';
@Injectable()
export class CtmService {
    data: CtmEventBody;

    // eslint-disable-next-line max-params
    constructor(
        private http: HttpClient,
        private session: SessionService,
        private apiCommon: BillingApiCommonService,
        private datePipe: DatePipe,
        @Inject('logger') private LOGGER: LoggerService
    ) { }

    setBillingCtmData(eventId: string, eventData: CtmEventData): CtmEventBody {
        const data = this.buildDefaultFieldsForCTM(eventData);
        switch (eventId) {
            case CtmEventType.PAYBILL_STARTED_POLICY: {
                data.mappingId = 'InternetServicing-0044';
                data.visibilityLevel = 'Agency';
                data.productType = this.setProductType(eventData);
                data.topicDescription = this.setTopicDescription(eventData, eventId);
                data.detailedDescription =
                    `The customer started the online bill pay process for the ${this.setProductType(
                        eventData
                    )} policy ${this.session.policyNumber}.`;
                data.businessItem = {
                    sourceApplication: eventData.sourceSystem,
                    id: this.session.policyNumber
                };
                break;
            }
            case CtmEventType.PAYBILL_SUCCESSFUL_POLICY: {
                data.mappingId = 'InternetServicing-0045';
                data.visibilityLevel =
                    data.createdByRepresentative.visibilityProducers &&
                        data.createdByRepresentative.visibilityProducers.length > 0 ? 'Agency' : 'Enterprise';
                data.productType = this.setProductType(eventData);
                data.topicDescription = this.setTopicDescription(eventData, eventId);
                data.detailedDescription =
                    `The customer submitted an online payment of $${eventData.paymentAmount} ` +
                    `using ${eventData.paymentMethod} toward the policy ${this.session.policyNumber} due ${eventData.dueDate}. ` +
                    `An email confirmation of this transaction was sent to ${this.session.emailId}.`;
                data.businessItem = {
                    sourceApplication: eventData.sourceSystem,
                    type: 'Policy',
                    id: this.session.policyNumber
                };
                break;
            }
            case CtmEventType.PAYBILL_STARTED_BILLING_ACCOUNT: {
                data.mappingId = 'InternetServicing-0013';
                data.visibilityLevel = 'Agency';
                data.businessItem = {
                    sourceApplication: this.session.sourceSystem,
                    id: this.session.billingAccountNumber
                };
                data.topicDescription = this.setTopicDescription(eventData, eventId);
                data.detailedDescription =
                    `The customer started the bill pay process via Internet Servicing for billing account` +
                    ` ${this.session.billingAccountNumber}.`;
                break;
            }
            case CtmEventType.PAYBILL_SUCCESSFUL_BILLING_ACCOUNT: {
                data.mappingId = 'InternetServicing-0014';
                data.visibilityLevel = 'Agency';
                data.businessItem = {
                    sourceApplication: this.session.sourceSystem,
                    id: this.session.billingAccountNumber
                };
                data.topicDescription = this.setTopicDescription(eventData, eventId);
                data.detailedDescription =
                    `The customer submitted a payment via Internet Servicing of $${eventData.paymentAmount} ` +
                    `using ${eventData.paymentMethod} toward Billing Account ${this.session.billingAccountNumber} due ` +
                    `${eventData.dueDate}. An email confirmation of this transaction was sent to ${this.session.emailId}.`;
                break;
            }
            case CtmEventType.PAYBILL_SCHEDULED_ACCOUNT_LEVEL: {
                data.mappingId = 'InternetServicing-0046';
                data.businessItem = {
                    sourceApplication: this.session.sourceSystem,
                    id: this.session.billingAccountNumber,
                    type: 'Billing Account'
                };
                data.productType = <CtmProductType>'Billing';
                data.topicDescription = this.setTopicDescription(eventData, eventId);
                data.detailedDescription =
                    `A payment of $${eventData.paymentAmount} has been scheduled online for ` +
                    `${eventData.dueDate} for Billing Account ${this.session.billingAccountNumber}.` +
                    ` A confirmation email was sent to ${this.session.emailId}.`;
                break;
            }
            case CtmEventType.PAYBILL_SCHEDULED_POLICY_LEVEL: {
                data.mappingId = 'InternetServicing-0046';
                data.businessItem = {
                    sourceApplication: eventData.sourceSystem,
                    id: this.session.policyNumber,
                    type: 'Policy'
                };
                data.productType = this.setProductType(eventData);
                data.topicDescription = this.setTopicDescription(eventData, eventId);
                data.detailedDescription =
                    `A payment of $${eventData.paymentAmount} has been scheduled online for ` +
                    `${eventData.dueDate} for Policy ${this.session.policyNumber}.` +
                    ` A confirmation email was sent to ${this.session.emailId}.`;
                break;
            }
            case CtmEventType.PAYBILL_REINSTATEMENT_SUCCESSFUL: {
                data.mappingId = 'InternetServicing-0048';
                data.businessItem = {
                    sourceApplication: eventData.sourceSystem,
                    id: this.session.policyNumber
                };
                data.productType = this.setProductType(eventData);
                data.topicDescription = this.setTopicDescription(eventData, eventId);
                data.detailedDescription =
                    `A ${eventData.paymentMethod} reinstatement payment was successfully received via ` +
                    `Internet Servicing for $${eventData.paymentAmount} on ${eventData.paymentDate}. Payment will be applied for policy ` +
                    `${this.session.policyNumber}. Confirmation Number is ${eventData.confirmationNumber}. A confirmation email was ` +
                    `sent to ${this.session.emailId}.`;
                break;
            }
            case CtmEventType.PAYBILL_REINSTATEMENT_UNSUCCESSFUL: {
                data.mappingId = 'InternetServicing-0049';
                data.visibilityLevel = 'Agency';
                data.businessItem = {
                    sourceApplication: eventData.sourceSystem,
                    id: this.session.policyNumber
                };
                data.productType = this.setProductType(eventData);
                data.topicDescription = this.setTopicDescription(eventData, eventId);
                data.detailedDescription =
                    `A ${eventData.paymentMethod} reinstatement payment was unsuccessful via Internet Servicing ` +
                    `for $${eventData.paymentAmount} on ${eventData.paymentDate}.`;
                break;
            }
            case CtmEventType.PP_REMOVE_PAYMENT_METHOD: {
                data.mappingId = 'InternetServicing-0038';
                data.businessItem = {
                    sourceApplication: this.session.sourceSystem, // TODO: from Brent: 'Sent email do we need to use SAP Billing or SAP',
                    id: this.session.billingAccountNumber
                };
                data.productType = this.setProductType(eventData);
                data.topicDescription = this.setTopicDescription(eventData, eventId);
                data.detailedDescription = `The customer removed a payment method on file. An email of this transaction was sent to ${this.session.emailId}.`;
                break;
            }
            case CtmEventType.PP_ADD_PAYMENT_METHOD: {
                data.mappingId = 'InternetServicing-0036';
                data.businessItem = {
                    sourceApplication: this.session.sourceSystem,
                    id: this.session.billingAccountNumber
                };
                data.productType = this.setProductType(eventData);
                data.topicDescription = this.setTopicDescription(eventData, eventId);
                data.detailedDescription = `The customer added a payment method on file. An email confirmation of this transaction was sent to ${this.session.emailId}.`;
                break;
            }
            case CtmEventType.PP_EDIT_PAYMENT_METHOD: {
                data.mappingId = 'InternetServicing-0037';
                data.businessItem = {
                    sourceApplication: this.session.sourceSystem,
                    id: this.session.billingAccountNumber
                };
                data.productType = this.setProductType(eventData);
                data.topicDescription = this.setTopicDescription(eventData, eventId);
                data.detailedDescription = `The customer Edited a payment method on file. An email confirmation of this transaction was sent to ${this.session.emailId}.`;
                break;
            }
            case CtmEventType.PP_SETUP_EASY_PAYMENT_METHOD: {
                data.mappingId = 'InternetServicing-0039';
                data.businessItem = {
                    sourceApplication: this.session.sourceSystem,
                    id: this.session.billingAccountNumber
                };
                data.productType = this.setProductType(eventData);
                data.topicDescription = this.setTopicDescription(eventData, eventId);
                data.detailedDescription = `The customer signed up for Easy Pay automatic payments for billing account ${this.session.billingAccountNumber},
      using payment method ${eventData.paymentMethod}. An email confirmation of this transaction was sent to ${this.session.emailId}.`;
                break;
            }
            case CtmEventType.PP_EDIT_EASY_PAYMENT_METHOD: {
                data.mappingId = 'InternetServicing-0040';
                data.businessItem = {
                    sourceApplication: this.session.sourceSystem,
                    id: this.session.billingAccountNumber
                };
                data.productType = this.setProductType(eventData);
                data.topicDescription = this.setTopicDescription(eventData, eventId);
                data.detailedDescription = `The customer updated their payment method ${eventData.paymentMethod} for Easy Pay automatic payments for billing account ${this.session.billingAccountNumber}.
      An email confirmation of this transaction was sent to ${this.session.emailId}.`;
                break;
            }
            case CtmEventType.SUSPEND_EASY_PAY_SUCCESSFUL: {
                data.mappingId = 'InternetServicing-0042';
                data.businessItem = {
                    sourceApplication: this.session.sourceSystem,
                    id: this.session.billingAccountNumber
                };
                data.productType = this.setProductType(eventData);
                data.topicDescription = this.setTopicDescription(eventData, eventId);
                data.detailedDescription = `The customer suspended this month's Easy Pay automatic payment for billing account ${this.session.billingAccountNumber}. An email confirmation of this transaction was sent to ${this.session.emailId}.`;
                break;
            }
            case CtmEventType.CANCEL_EASY_PAY_SUCCESSFUL: {
                data.mappingId = 'InternetServicing-0041';
                data.businessItem = {
                    sourceApplication: this.session.sourceSystem,
                    id: this.session.billingAccountNumber
                };
                data.productType = this.setProductType(eventData);
                data.topicDescription = this.setTopicDescription(eventData, eventId);
                data.detailedDescription = `The customer cancelled and stopped Easy Pay automatic payments for billing account ${this.session.billingAccountNumber}. An email confirmation of this transaction was sent to ${this.session.emailId}.`;
                break;
            }
            case CtmEventType.CANCEL_SCHEDULE_FUTURE_PAYMENT_SUCCESSFUL: {
                data.mappingId = 'InternetServicing-0047';
                data.businessItem = {
                    sourceApplication: this.session.sourceSystem,
                    id: this.session.billingAccountNumber,
                    type: 'Billing Account'
                };
                data.productType = this.setProductType(eventData);
                data.topicDescription = this.setTopicDescription(eventData, eventId);
                data.detailedDescription =
                    `The payment of ${eventData.paymentAmount} scheduled for ${eventData.paymentDate} for` +
                    ` ${eventData.productType} ${this.session.billingAccountNumber} has been cancelled.` +
                    ` A confirmation email was sent to ${this.session.emailId}.`;
                break;
            }
            case CtmEventType.ON_CLICK_OF_REMOVE_REFUND_METHOD_BTN: {
                data.mappingId = 'InternetServicing-0052';
                data.businessItem = {
                    sourceApplication: this.session.sourceSystem,
                    id: this.session.billingAccountNumber,
                    type: 'Billing Account'
                };
                data.productType = this.setProductType(eventData);
                data.topic = 'Manage Billing Information';
                data.topicDescription = this.setTopicDescription(eventData, eventId);
                data.detailedDescription =
                    `The customer unenrolled from EFT Refunds via Internet Servicing for billing account ${this.session.billingAccountNumber}.` +
                    ` A confirmation email was sent to ${this.session.emailId}.`;
                break;
            }
            case CtmEventType.PP_CHANGEDUEDATE_SUCCESSFUL: {
                data.mappingId = 'InternetServicing-0053';
                data.businessItem = {
                    sourceApplication: this.session.sourceSystem,
                    id: this.session.billingAccountNumber,
                    type: 'Billing Account'
                };
                data.productType = this.setProductType(eventData);
                data.topic = 'Manage Billing Information';
                data.topicDescription = this.setTopicDescription(eventData, eventId);
                data.detailedDescription = `Bill due date was changed via ${this.session.sourceSystem}. The previous due date was ${eventData.currentBillDueDate} of the month.
      The new billing due date is on the ${eventData.nextBillingDueDate} of the month. This new due date will pertain to the next billing cycle.
      A confirmation email was sent to ${this.session.emailId}.`;
                break;
            }
            case CtmEventType.PP_ADD_REFUND_METHOD: {
                data.mappingId = 'InternetServicing-0050';
                data.businessItem = {
                    sourceApplication: this.session.sourceSystem,
                    id: this.session.billingAccountNumber,
                    type: 'Billing Account'
                };
                data.productType = this.setProductType(eventData);
                data.topic = 'Manage Billing Information';
                data.topicDescription = this.setTopicDescription(eventData, eventId);
                data.detailedDescription =
                    `The customer enrolled into EFT Refunds via Internet Servicing for billing account ${this.session.billingAccountNumber}.` +
                    ` A confirmation email was sent to ${this.session.emailId}.`;
                break;
            }
            // No default
        }
        return data;
    }

    buildDefaultFieldsForCTM(eventData: CtmEventData): CtmEventBody {
        const aimsProducerNumberLength = 12;
        const cyberlifeProducerNumberLength = 9;
        const digitOnlyRegex = /^\d+$/;
        let visibilityProducers;
        try {
            // valid producer numbers are those that are not length 12 and those that are length 9 must be numeric only
            const validProducers = eventData.producerNumbers.filter((producerNumber) =>
                !(producerNumber.length === aimsProducerNumberLength || producerNumber.length === cyberlifeProducerNumberLength && !digitOnlyRegex.test(producerNumber))
            );
            visibilityProducers = validProducers.map((producerNumber) => (
                { producerNumber }
            ));
        } catch {
            visibilityProducers = [];
        }

        this.data = {
            authenticationId: 'MyNW',
            transactionId: this.apiCommon.generateTransactionId(),
            mappingId: '',
            applicationSource: 'Internet Servicing',
            visibilityLevel: 'Enterprise',
            createdByRepresentative: {
                visibilityProducers
            },
            customer: {
                name: `${this.session.lastName}, ${this.session.firstName}`,
                enterpriseCustomerNumber: this.session.ecn
            },
            detailedDescription: '',
            interactionGrouping: {
                id: this.session.a2aSessionId
            }
        };

        if (this.data.createdByRepresentative.visibilityProducers.length === 0) {
            delete this.data.createdByRepresentative.visibilityProducers;
        }

        return this.data;
    }

    setProductType(eventData: CtmEventData): CtmProductType {
        const productType = CtmProductTypeMap.get(<ProductType>eventData.productType);
        return productType == null ? CtmProductType.OTHER : productType;
    }

    callCtm(ctmEventBody: CtmEventBody): void {
        const authToken = this.session.accessToken;

        let headers = this.apiCommon.buildHeader(
            { appClientId: this.apiCommon.getConstantValue('client_id'), authToken, nwAppId: this.apiCommon.getConstantValue('nw_APP_ID'), contentType: this.apiCommon.getConstantValue('content_Type'), headerType: 'X-Nw-Transaction-Id' });

        headers = headers.append('realTimeResponseIndicator', 'N');

        const url = `${environment.CTM.ctmEndPoint}?apikey=${this.apiCommon.getConstantValue('client_id')}`;

        this.LOGGER.info(`Ctm event ${ctmEventBody.mappingId} was sent with the following data:`, ctmEventBody);
        this.http.post(url, ctmEventBody, { headers }).pipe(
            catchError((error) => of({ error }))
        ).pipe(
            map((ctmResponse: any) => {
                if (ctmResponse.interactionId) {
                    this.LOGGER.info(`Ctm response:${ctmResponse.interactionId}`, ctmResponse);
                } else if (ctmResponse.transactionId) {
                    this.LOGGER.info(`CTM response${ctmResponse.transactionId}`, ctmResponse);
                } else {
                    this.LOGGER.error('Did not receive interactionId or transactionId from CTM');
                }
            })).subscribe();
    }

    private setTopicDescription(data: CtmEventData, eventId: string): string {
        let output: string;

        switch (eventId) {
            case CtmEventType.PAYBILL_STARTED_POLICY: output = 'Started Pay Bill via Internet Servicing';
                break;
            case CtmEventType.PAYBILL_SUCCESSFUL_POLICY: output = data.paymentMethod === 'Bank Account' ?
                'Pay Bill via Internet Servicing with Bank Account.' : 'Pay Bill via Internet Servicing with Bank Card';
                break;
            case CtmEventType.PAYBILL_STARTED_BILLING_ACCOUNT: output = 'Pay Bill Via Internet Servicing';
                break;
            case CtmEventType.PAYBILL_SUCCESSFUL_BILLING_ACCOUNT: output = 'Pay Bill Via Internet Servicing';
                break;
            case CtmEventType.PAYBILL_SCHEDULED_ACCOUNT_LEVEL: case CtmEventType.PAYBILL_SCHEDULED_POLICY_LEVEL:
                output = 'Future Payment Scheduled Via Internet Servicing';
                break;
            case CtmEventType.PAYBILL_REINSTATEMENT_SUCCESSFUL: output = 'Reinstatement Payment Successful Via Internet Servicing';
                break;
            case CtmEventType.PAYBILL_REINSTATEMENT_UNSUCCESSFUL: output = 'Reinstatement Payment Unsuccessful Via Internet Servicing';
                break;
            case CtmEventType.PP_REMOVE_PAYMENT_METHOD: output = 'Remove Online Payment Method on File';
                break;
            case CtmEventType.PP_ADD_PAYMENT_METHOD: output = 'Add a payment method on file';
                break;
            case CtmEventType.PP_EDIT_PAYMENT_METHOD: output = 'Edit online payment method on file';
                break;
            case CtmEventType.PP_SETUP_EASY_PAYMENT_METHOD: output = 'User successfully enrolls in Easy Pay';
                break;
            case CtmEventType.PP_EDIT_EASY_PAYMENT_METHOD: output = 'User successfully submits a payment method change';
                break;
            case CtmEventType.SUSPEND_EASY_PAY_SUCCESSFUL: output = 'Suspend Easy Pay Automatic Payment';
                break;
            case CtmEventType.CANCEL_EASY_PAY_SUCCESSFUL: output = 'Cancel Easy Pay Automatic Payment';
                break;
            case CtmEventType.CANCEL_SCHEDULE_FUTURE_PAYMENT_SUCCESSFUL: output = 'Scheduled future payment cancelled successfully';
                break;
            case CtmEventType.ON_CLICK_OF_REMOVE_REFUND_METHOD_BTN: output = 'EFT Refunds Unenrollment Via Internet Servicing';
                break;
            case CtmEventType.PP_ADD_REFUND_METHOD: output = 'EFT Refunds Enrollment Via Internet Servicing';
                break;
            case CtmEventType.PP_CHANGEDUEDATE_SUCCESSFUL: output = 'Bill Due Date Changed Via Internet Servicing';
                break;
        }

        return output;
    }
}
