import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APICommonService } from '@nationwide/internet-servicing-angular-services';
import {
    ContextCacheService,
    PBS,
    PbsService
} from '@nationwide/dgs-angular-billing-common';
import {
    catchError, map, Observable, of, shareReplay,
    tap, timeout
} from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PaymentFlowType } from '../../../pay-bill/shared/payment-flow-type.service';
import { LoggerService } from '../../logger/logger.service';
import { SessionService } from '../../session/session.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class HttpCachingService {
    private retrieveCustomerAgreement$: Observable<PBS.RetriveCustomerAgreementResponse>;
    private rcaResponse: PBS.RetriveCustomerAgreementResponse;

    private DISALLOWED_URLS = [
        '/personal/billing/landing',
        '/personal/billing/picker'
    ];

    // eslint-disable-next-line max-params
    constructor(
        @Inject('window') public window: Window,
        private sessionService: SessionService,
        private paymentFlowType: PaymentFlowType,
        private pbsService: PbsService,
        private apiCommon: APICommonService,
        @Inject('logger') private logger: LoggerService,
        private router: Router,
        private contextCacheService: ContextCacheService,
        private activatedRoute: ActivatedRoute

    ) {
        if (this.isPBSCallAllowed() && (this.activatedRoute.snapshot.queryParams.myAccountUserSessionId || sessionStorage.getItem('camSessionId'))) {
            this.retrieveCustomerAgreement$ = this.setupRetrieveCustomerAgreement();
        }
    }

    checkAndRetrieveContextCache(): Observable<any> {
        return this.getParameterByName('contextId') ? this.retrievePolicyContextCache() : of({});
    }

    clearCache(): void {
        if (this.sessionService.billingAccountNumberForRCA !== 'NA') {
            this.retrieveCustomerAgreement$ = this.refreshRCAData();
        }
        this.fetchRetrieveCustomerAgreementResponse().subscribe();
    }

    fetchRetrieveCustomerAgreementResponse(): Observable<any> {
        if (this.activatedRoute.snapshot.queryParams.myAccountUserSessionId || sessionStorage.getItem('myAccountUserSessionId')) {
            if (!this.retrieveCustomerAgreement$ && this.isPBSCallAllowed()) {
                this.retrieveCustomerAgreement$ = this.setupRetrieveCustomerAgreement();
            }
        } else if (sessionStorage.getItem('BILLING_ACCOUNT_NUMBER')) {
            this.retrieveCustomerAgreement$ = this.setupRetrieveCustomerProfile();
        }
        return this.retrieveCustomerAgreement$;
    }

    private fetchContextCache(contextId: string): Observable<any> {
        const params = {
            accessToken: sessionStorage.getItem('accessToken').split(',')[0],
            contextId
        };
        return this.contextCacheService.retrieveContext(params)
            .pipe(
                map((response) => response),
                catchError((error: HttpErrorResponse) => of(error))
            );
    }

    private getParameterByName(name: string): string {
        const url = this.window.location.href;
        name = name.replace(/[[\]]/g, '\\$&');
        const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
        const results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    private retrievePolicyContextCache(): any {
        const contextCacheContextId = this.getParameterByName('contextId');
        return this.fetchContextCache(contextCacheContextId);
    }

    private setupRetrieveCustomerAgreement(): Observable<PBS.RetriveCustomerAgreementResponse> {
        const pbsCall = this.paymentFlowType?.isSplitPartyUser ? this.pbsService.retrieveSplitPartyCustomerAgreement(this.pbsRequestParams()) :
            this.pbsService.retrieveCustomerAgreement(this.pbsRequestParams());
        return pbsCall.pipe(
            timeout(environment.TIMEOUT.apiCallDuration.pbs),
            tap((rcaResponse) => {
                this.sessionService.intitialize({ rcaResponse });
                this.rcaResponse = rcaResponse;
            }),
            catchError((err) => {
                this.logger.error('API ERROR: RETRIEVE CUSTOMER AGREEMENT', err);
                return of(null);
            }),
            shareReplay(1)
        );
    }

    private setupRetrieveCustomerProfile(): Observable<PBS.RetriveCustomerAgreementResponse> {
        return this.pbsService.retrieveCustomerProfile(this.pbsPortfolioRequestParams(sessionStorage.getItem('BILLING_ACCOUNT_NUMBER')?.toString())).pipe(
            map((rcpResponse) => {
                rcpResponse['retrieveCustomerAgreementResponse'] = rcpResponse.retrieveCustomerPortfolioResponse;
                delete rcpResponse.retrieveCustomerPortfolioResponse;
                return rcpResponse;
            }));
    }

    private pbsRequestParams(): any {
        return {
            isFiredOnMAPLanding: true,
            agreementNumber: this.sessionService.billingAccountNumberForRCA,
            sessionId: this.sessionService.a2aSessionId,
            accessToken: this.sessionService.accessToken,
            transactionId: this.apiCommon.generateTransactionId()
        };
    }

    private pbsPortfolioRequestParams(agreementNumber: string): any {
        return {
            agreement: [
                {
                    refId: agreementNumber,
                    agreementNumber,
                    agreementType: 'BILLING',
                    system: 'SAP BILLING'
                }
            ],
            sessionId: this.sessionService.a2aSessionId,
            accessToken: this.sessionService.accessToken,
            transactionId: this.apiCommon.generateTransactionId()
        };
    }

    private refreshRCAData(): Observable<PBS.RetriveCustomerAgreementResponse> {
        return this.pbsService.retrieveCustomerAgreementWithRefresh({
            isFiredOnMAPLanding: false,
            agreementNumber: this.sessionService.billingAccountNumberForRCA,
            sessionId: this.sessionService.a2aSessionId,
            accessToken: this.sessionService.accessToken,
            transactionId: this.apiCommon.generateTransactionId()
        })
            .pipe(
                map((rcawrResponse) => {
                    rcawrResponse = this.mapRcawrToRcaResponse(this.rcaResponse, rcawrResponse);
                    return rcawrResponse;
                }),
                tap((rcaResponse) => {
                    this.sessionService.intitialize({ rcaResponse });
                }),
                timeout(environment.TIMEOUT.apiCallDuration.pbs),
                catchError((err) => {
                    this.logger.error('API ERROR: RETRIEVE CUSTOMER AGREEMENT WITH REFRESH', err);
                    return of(null);
                }),
                shareReplay(1)
            );
    }

    // RCAWR only returns the agreement info for the agreement passed in, so we have to update the original RCA response to keep the other agreement info intact
    private mapRcawrToRcaResponse(rcaResponse: PBS.RetriveCustomerAgreementResponse, rcawrResponse: PBS.RetriveCustomerAgreementResponse): PBS.RetriveCustomerAgreementResponse {
        const mappedRcaResponse = rcaResponse;
        let rcaAgreementIndex: number;

        if (rcawrResponse != null && rcaResponse != null) {
            const rcawrAgreements = this.getAgreements(rcawrResponse);
            const rcaAgreements = this.getAgreements(mappedRcaResponse);

            rcawrAgreements.forEach((rcawrAgreement) => {
                rcaAgreementIndex = rcaAgreements.findIndex((rcaAgreement) => rcaAgreement.refId === rcawrAgreement.refId);

                if (rcaAgreementIndex > -1) {
                    rcaAgreements[rcaAgreementIndex] = rcawrAgreement;
                } else {
                    rcaAgreements.push(rcawrAgreement);
                }
            });

            mappedRcaResponse.retrieveCustomerAgreementResponse.return.profile.agreementGroup.agreement = rcaAgreements;
        }

        return mappedRcaResponse;
    }

    private getAgreements(rcaResponse): PBS.Agreement[] {
        const agreements = rcaResponse.retrieveCustomerAgreementResponse.return.profile
            .agreementGroup.agreement;
        return agreements instanceof Array ? agreements : [agreements];
    }

    private isPBSCallAllowed(): boolean {
        return this.sessionService.billingAccountNumberForRCA !== 'NA' && this.isUrlAllowed();
    }

    private isUrlAllowed(): boolean {
        return !this.DISALLOWED_URLS.find((url) => this.router.url.includes(url));
    }

    getRetrieveCustomerAgreement(): Observable<PBS.RetriveCustomerAgreementResponse> {
        return this.retrieveCustomerAgreement$;
    }

    setRetrieveCustomerAgreement(observable: Observable<PBS.RetriveCustomerAgreementResponse>): void {
        this.retrieveCustomerAgreement$ = observable;
    }
}
