import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, of, finalize, map, tap, catchError } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { FormUtil } from '../../../../../../shared/payments/form-util';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { PaymentMethodsAdapter } from '../../../../shared/adapters/payment-methods.adapter';
import { PaymentItem } from '../../../../shared/models/payment-item.model';
import { PaymentPreferencesApiService } from '../../../../shared/services/payment-preferences-api/payment-preferences-api.service';
import { ManagePaymentPrefsFormBuilder } from '../../../base/manage-payment-prefs-form-builder';
import { ManagePaymentPrefsAdapter } from '../../../shared/adapters/manage-payment-prefs-adapter';
import { AddRefundMethodFlowService } from '../services/add-refund-method-flow.service';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { PlaceholderPaymentItem } from '../../../../shared/models/placeholder-item.model';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { SessionService } from '../../../../../../shared/session/session.service';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';

@Component({
    selector: 'app-add-refund-method-landing',
    templateUrl: './add-refund-method-landing.component.html',
    styleUrls: ['./add-refund-method-landing.component.scss']
})
export class AddRefundMethodLandingComponent extends BasePageComponent implements OnInit {
    @ViewChild(FormGroupDirective) addRefundMethodFormDirective: FormGroupDirective;
    pageName = ManagePaymentPreferencesPageTitle.refundMethod.add;
    addRefundMethodFormGroup: PaymentFormGroup;
    savedEligiblePaymentMethods: Observable<PaymentItem[]>;
    savedPaymentMethods: PaymentItem[] = [];
    emailAddress: string;
    _selectedPaymentMethod: PaymentItem;
    loadComplete = false;
    formValidated = false;

    // eslint-disable-next-line max-params
    constructor(
        protected router: Router,
        @Inject('logger') private logger: LoggerService,
        private urlUtil: UrlUtil,
        protected session: SessionService,
        private paymentPrefsApi: PaymentPreferencesApiService,
        private paymentMethodAdapter: PaymentMethodsAdapter,
        private paymentPrefsFormBuilder: ManagePaymentPrefsFormBuilder,
        private addRefundMethodFlowService: AddRefundMethodFlowService,
        private managePaymentPrefsAdapter: ManagePaymentPrefsAdapter,
        private formUtils: FormUtil) {
        super(session, router);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.setAddSavedRefundMethodData();
        this.initSelectedPayMethod();
    }

    setAddSavedRefundMethodData(): void {
        const includeNewBankCard = false;
        this.savedEligiblePaymentMethods = this.paymentPrefsApi.addSavedRefundMethodLanding()
            .pipe(
                map(({ retrieveCustomerAgreement, internetRegistrationResponse, savedBankAccountMethods }) => {
                    this.emailAddress = this.managePaymentPrefsAdapter.fetchMostCurrentEmail(retrieveCustomerAgreement, internetRegistrationResponse);
                    return this.paymentMethodAdapter.mapSortPayMethods(savedBankAccountMethods, { includeNewBankCard });
                }),
                tap((savedEligiblePaymentMethods) => {
                    if (this.addRefundMethodFlowService.flowInProgress) {
                        this.selectedPaymentMethod = savedEligiblePaymentMethods[savedEligiblePaymentMethods.findIndex((method) => method.id === this.addRefundMethodFlowService.selectedPaymentMethod.id)];
                    } else {
                        this.selectedPaymentMethod = savedEligiblePaymentMethods[0];
                    }
                }),
                catchError((err) => {
                    this.logger.error('API ERROR: ADD REFUND METHOD LANDING', err);
                    return of(err);
                }),
                finalize(() => this.loadComplete = true)
            );
        this.savedEligiblePaymentMethods.subscribe((methods) => {
            this.savedPaymentMethods = methods;
        });
    }

    navigateToReview(): void {
        this.formUtils.touchAllFormControls(this.addRefundMethodFormGroup.group);
        if (this.addRefundMethodFormGroup.group.valid) {
            this.loadComplete = false;
            this.saveAddSavedRefundData();
            this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.SAVED_REFUND_METHOD.ADD.REVIEW(
                this.urlUtil.hashParamsString
            ));
        } else {
            this.formValidated = false;
        }
    }

    navigateToPaymentPreferences(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(
            this.urlUtil.hashParamsString
        ));
    }

    setupAddSavedRefundMethodForm(): void {
        if (this.addRefundMethodFlowService.flowInProgress && this.addRefundMethodFlowService.isNewBankAccount) {
            this.addRefundMethodFormGroup = this.paymentPrefsFormBuilder.newReconstructFormGroup({
                oldForm: this.addRefundMethodFlowService.addRefundMethodFormGroup.group
            });
        } else {
            this.addRefundMethodFormGroup = this.paymentPrefsFormBuilder.newConsiderThisFormGroup();
        }
    }

    saveAddSavedRefundData(): void {
        const isNewBankAccount = this.selectedPaymentMethod.isNewMethod;
        this.addRefundMethodFlowService.save(
            {
                paymentItem: this._selectedPaymentMethod,
                emailAddress: this.emailAddress,
                addRefundMethodFormGroup: this.addRefundMethodFormGroup,
                isNewBankAccount
            }
        );
    }

    initSelectedPayMethod(): void {
        this._selectedPaymentMethod = this.getSelectDefaultPayMethod();
    }

    getSelectDefaultPayMethod(): PaymentItem {
        const hasDefaultMethod = this.savedPaymentMethods.find(
            (method) =>
                method.isDefault
        );
        if (this.savedPaymentMethods.length === 0 || !hasDefaultMethod) {
            return new PaymentItem(new PlaceholderPaymentItem(''));
        } else {
            return this.savedPaymentMethods.find(
                (method) =>
                    method.isDefault
            );
        }
    }

    get selectedPaymentMethod(): PaymentItem {
        return this._selectedPaymentMethod;
    }

    set selectedPaymentMethod(paymentItem: PaymentItem) {
        if (paymentItem) {
            this.setupAddSavedRefundMethodForm();
            this._selectedPaymentMethod = paymentItem;
            this.formValidated = true;
        } else {
            this._selectedPaymentMethod = null;
        }
    }

    get submitted(): boolean {
        return this.addRefundMethodFormDirective && this.addRefundMethodFormDirective.submitted;
    }

    get isPageLevelError(): boolean {
        return !this.formValidated && !this.addRefundMethodFormGroup.group.valid &&
            this.submitted;
    }

    private hasDefaultMethod(): boolean {
        return !!this.savedPaymentMethods.find((method) => method.isDefault);
    }

    private hasSavedPaymentMethod(): boolean {
        return !!this.savedPaymentMethods.find((method) => !method.isNewMethod && !method.isPlaceholder);
    }

    get canDisplayAccountInfoHeader(): boolean {
        return !this.selectedPaymentMethod?.isPlaceholder;
    }
}