<is-wait-message *ngIf="!loadComplete" [loadComplete]="loadComplete" [useBolt]="false" [waitMessage]="'Please wait while we process your request.'">
</is-wait-message>
<ng-container *ngIf="savedPaymentMethods.length">
    <form [formGroup]="addRefundMethodFormGroup.group" *ngIf="addRefundMethodFormGroup" class="manage-payment-form-container" (ngSubmit)="navigateToReview()">
        <div class="bolt-container">

            <bw-bolt-progress-bar appFocusOnInit arialabel="add refund method progress value is 33%" class="progress-bar-container to-container" progressBarValue="33"></bw-bolt-progress-bar>
            <h1 class="bolt-heading-primary bolt-space-bottom-2xl">Add refund method</h1>

            <div class="bolt-row">
                <div class="bolt-col-12">
                    <bw-bolt-notification type="error" [id]="'global-error-message'" *ngIf="isPageLevelError" appFocusOnInit class="bolt-space-bottom-xl to-container">
                        Please check the information below and try again.
                    </bw-bolt-notification>

                    <app-payment-sub-header *ngIf="canDisplayAccountInfoHeader">Account information</app-payment-sub-header>

                    <div class="bolt-space-bottom-lg">
                        <app-payment-method-section [hasDefaultMethod]="hasDefaultMethod(savedPaymentMethods)" [hasSavedPaymentMethod]="hasSavedPaymentMethod(savedPaymentMethods)" [(selectedPaymentMethod)]="selectedPaymentMethod">
                        </app-payment-method-section>
                    </div>
                </div>
            </div>
        </div>
        <bolt-button-bar>
            <bolt-button slot="cancel" aria-label="cancel" id="cancel-button" (click)="navigateToPaymentPreferences()">Cancel</bolt-button>
            <bolt-button submit [disabled]="selectedPaymentMethod?.isPlaceholder" slot="forward" type="primary submit" aria-label="continue" id="continue-button">Continue</bolt-button>
        </bolt-button-bar>
    </form>
</ng-container>