import { Inject, Injectable } from '@angular/core';
import { LoggerService } from '../../logger/logger.service';
import { BillingApiCommonService } from '../billing-api-common.service';

@Injectable({
    providedIn: 'root'
})
export class EbiApiService {
    constructor(
        private apiCommon: BillingApiCommonService,
        @Inject('logger') private LOGGER: LoggerService
    ) { }

    sendEbiEvent(eventId: string, eventPayload: object): void {
        const data = {
            appCookie: this.apiCommon.getConstantValue('appCookie'),
            eventId,
            sessionId: this.apiCommon.getConstantValue('sessionId'),
            data: eventPayload
        };

        this.LOGGER.event(`Ebi Event ${eventId} was sent with following data: `, data);
    }
}
